import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import PageLoader from '../../../Components/Loader/PageLoader';
import useTitle from '../../../Hooks/useTitle';
import TextInput from '../../../Components/Input';
import useClipboard from "react-use-clipboard";
import { MdHls, MdOutlineContentCopy } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { FaExclamationCircle } from "react-icons/fa";
import reactSelect from 'react-select';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";

export default function SpecialUser() {
    
    const defaultPlan = {
        plan_name:"",
        monthly_price:"",
        no_words:"",
        description:"",
        is_special: 0,        
        no_days: 0
    };

    
    const defaultFeature = {
        feature:"",
        pricing_plan_id:"",
    }

    const defaultUrl = {
        url: "/ecomemail/special/user"
    }
    const mainUrl = window.location.href;
    const baseUrl = new URL(mainUrl).origin;
    const [pageLoading, setPageLoading ] = useState(true);
    const [url, setURL] = useState(defaultUrl);
    const [plan, setPlan] = useState(defaultPlan);
    const [showPlan, setShowPlan] = useState(false);
    const [planId, setPlanId] = useState(0);
    const [feature, setFeature] = useState(defaultFeature);
    const [showURL, setShowURL] = useState(false);
    const [showFeature, setShowFeature] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectedAfterPlan, setSelectedAfterPlan] = useState(null);
    const [activatedOptions, setActivatedOptions] = useState([]);
    const [isCopied, setCopied] = useClipboard(`${baseUrl}${url.url}`, {
        successDuration: 1000,
      });
    const navigate = useNavigate();
    useTitle("Prompts");
    
    const getUrl = () => {
        axios.get(`${Helpers.apiUrl}special/get`, Helpers.authHeaders).then((response)=>{
            setURL(response.data.special);
            setPageLoading(false);
        }).catch(err=>{
            Helpers.toast('error', err.message);
            console.log(err)
            setPageLoading(false)
        })
    }

    const handleSaveURL = () => {
        setPageLoading(true);
        axios.post(`${Helpers.apiUrl}special/save`, url, Helpers.authHeaders).then(response=>{
            Helpers.toast('success', response.data.message);
            setURL(response.data.special);
            setPageLoading(false)
        }).catch(err=>{
            Helpers.toast('error', err.message)
            setPageLoading(false)
            console.log(err)
        })
    }

    const handleCancel = () => {
        setShowPlan(!showPlan);
        setShowFeature(false);
        setShowURL(false)
    }
    
    const savePlan = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}plans/save`, plan, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setPlan(defaultPlan);
            getSpecial();
            setIsLoading(false);
            setShowPlan(false)
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
            setShowPlan(false)
        });
    }

    const handleEditFeature = () => {
        setShowFeature(true);
        setShowPlan(false)
    }

    const handleShowPlan = () => {
        setShowURL(false)
        setShowPlan(!showPlan);
        setShowFeature(false)
    }

    const getSpecial = () => {
        setPageLoading(true);
        axios.get(`${Helpers.apiUrl}plans/getSpecial`, Helpers.authHeaders).then(response => {
            setPlan(response.data.plan);
            setPlanId(response.data.plan.id)
            setPageLoading(false)
        }).catch(error=>{
            Helpers.toast('error', error.message)
            console.log(error)
            setPageLoading(false)
        })
    }

    
    const deleteFeature = (featureId) => {
        axios.get(`${Helpers.apiUrl}plans/feature/delete/${featureId}`, Helpers.authHeaders).then(response => {
            getSpecial();
            setShowFeature(false);
            setShowPlan(false)
            Helpers.toast("success", response.data.message);
        });
    }

    const editFeature = fearureToEdit => {
        setFeature(fearureToEdit);
    }

    
    const handleFeatureCancel = () => {
        // setPlan(defaultPlan);
        // setFeature(defaultFeature);
        setShowFeature(false);
        setShowPlan(false);
        setShowURL(false)
    }
    
    const saveFeature = () => {
        if(feature.feature){
            setIsLoading(true);
            let data = feature;
            data.pricing_plan_id = plan.id;
            axios.post(`${Helpers.apiUrl}plans/feature/save`, data, Helpers.authHeaders).then(response => {
                getSpecial();
                setPlanId(null)
                setPlan(defaultPlan)
                setFeature(defaultFeature);
                Helpers.toast("success", response.data.message);
                setIsLoading(false);
            }).catch(error => {
                Helpers.toast("error", error.response.data.message);
                setIsLoading(false);
            });
        }else{
            Helpers.toast("error", "Please add feature to save");
        }
    }

    const toggleURL = () => {
        setShowURL(!showURL);
        setShowFeature(false);
        setShowPlan(false);
    }

    const getPlans = () => {
        setPageLoading(true);
        axios.get(`${Helpers.apiUrl}plans/all`, Helpers.authHeaders).then(response => {
            handleSetActivatedOptions(response.data.plans);
            setPageLoading(false);
        });
    }

    const handleSetActivatedOptions = (data) => {
        let options = [];
        for(let i = 0; i < data.length; i++){
            if (data[i].is_trial === 0) {
                let option = {
                    label: data[i].plan_name,
                    value: data[i].id,
                }
                options.push(option);
            }
        }
        console.log(options);
        setActivatedOptions(options);
    } 

    const handleActivatePackage = (id) => {
        setIsLoading(true)
        axios.post(`${Helpers.apiUrl}plans/renewal/activate/${id}`, {}, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setIsLoading(false);
            getPlans();
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        });
    }

    useEffect(()=>{
        getPlans();
        getUrl();
        getSpecial();
    }, [])
  return (
    <div class="nk-content">
    <div class="container-xl">
        <div class="nk-content-inner">
            {!(planId) ? <PageLoader /> : <div class="nk-content-body">
                <div class="nk-block-head nk-page-head">
                    <div class="nk-block-head-between">
                        <div class="nk-block-head-content">
                                <h2 class="display-6">Starter Plan</h2>
                                <p>Manage Starter Plan</p>
                        </div>
                    </div>
                                <div className='d-flex justify-content-end'>
                                <div className="d-flex flex-column justify-content-around align-items-center flex-md-row mx-2">
                                    <Select 
                                    value={selectedAfterPlan}
                                    options={activatedOptions} 
                                    placeholder={"Selected Activated Free Trial"}
                                    onChange={selectedAfterPlan=> {setSelectedAfterPlan(selectedAfterPlan)}}
                                    ></Select>
                                    <button className="btn btn-primary mx-2" onClick={()=>handleActivatePackage(selectedAfterPlan.value)}><em class="icon ni ni-update mr-1"></em> Post-Trial Renewal</button>
                                </div>
                                    <button className='btn btn-primary' onClick={toggleURL}> {showURL ? "Manage Plan" : "Mange URL"}
                                    </button>
                                </div>
                </div>

                {(isLoading && pageLoading) ? <PageLoader></PageLoader> : (<div class={`col-xl-4`}>
                    { (!showURL && !showPlan && !showFeature) && (<div class={`card pricing rounded-start bg-white`}>
                        <div class={`pricing-content`}
                        // style={(pln.is_special == 1) ? {border: "2px solid rgb(201, 84, 201)"}: {}}
                        >
                            <div class="w-sm-70 w-md-50 w-xl-100 text-center text-xl-start mx-auto">
                                <h2 class="mb-3">{ plan.plan_name }</h2>
                                <div class="pricing-price-wrap">
                                        <h3 class="display-1 mb-3 fw-semibold">${ parseFloat(plan.monthly_price).toFixed(2) } <span class="caption-text text-light fw-normal"> / month</span></h3>
                                </div>
                                <div class="mb-2">
                                    <button 
                                    onClick={handleShowPlan}
                                    class="btn btn-outline-light w-50">
                                        Edit Plan
                                    </button>
                                    <button 
                                    onClick={handleEditFeature}
                                    class="btn btn-outline-light w-50">
                                        Edit Features
                                    </button>
                                </div>
                                <ul class="pricing-features">
                                    {plan.plan_features?.map(p_feature => <li><em class="icon text-primary ni ni-check-circle"></em><span>{ p_feature.feature }</span></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>)}
                </div>)}

                {(showURL && !showPlan && !showFeature) &&(<div class="nk-block">
                    <div class="mt-xl-5">
                        <div class="row g-0">
                            <div className='card col-md-12'>
                                <div className='card-body shadow-none'>
                                    <TextInput 
                                    error={url.error}
                                    label={"Link"} 
                                    value={url.url} 
                                    onChange={(e)=>setURL({...url, url: e.target.value})} 
                                    cols={12}
                                    ></TextInput>
                                    <small className='text-muted'>
                                        <FaExclamationCircle className="mx-1"/>
                                        Must not include admin or user
                                    </small><br/>
                                    <small className='text-muted'>
                                        <FaExclamationCircle className="mx-1"/>
                                        Must not be same as primary registration URL
                                    ('/ecomemail/register')</small>
                                    <div className='m-2 d-flex justify-content-end'>
                                    <button className='btn btn-primary' onClick={handleSaveURL}>Save</button>
                                    <button className='btn btn-light' onClick={setCopied}>
                                        {isCopied ? <FaCheck /> : <MdOutlineContentCopy/>}
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}

                {(showPlan && !showFeature) && 
                 (<div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Add New Pricing Plan</h3></div>
                            </div>
                            <div class={`card shadown-none`}>
                                <div class="card-body">
                                    <div className='d-flex justify-content-end'>
                                    <button className='btn btn-outline-primary' onClick={handleEditFeature}>Edit Features</button>
                                    </div>
                                    <div class="row g-3 gx-gs">
                                        <TextInput error={errors.plan_name} label={"Plan Name"} cols={12} value={plan.plan_name} onChange={e => setPlan({...plan, plan_name: e.target.value})} />
                                        <TextInput error={errors.monthly_price} label={"Monthly Price"} value={plan.monthly_price} onChange={e => setPlan({...plan, monthly_price: e.target.value})} />
                                        <TextInput error={errors.no_words} label={"No. of Words"} value={plan.no_words} onChange={e => setPlan({...plan, no_words: e.target.value})} />
                                        <TextInput isTextArea={false} error={errors.no_days} label={"No of Days"} cols={12} value={plan.no_days} onChange={e => setPlan({...plan, no_days: e.target.value})} />
                                        <TextInput isTextArea={true} error={errors.description} label={"Description"} cols={12} value={plan.description} onChange={e => setPlan({...plan, description: e.target.value})} />
                                        <div className="col-md-12">
                                            <button className="btn btn-primary" disabled={isLoading} onClick={savePlan}>{isLoading ? 'Saving...' : 'Save Pricing Plan'}</button>
                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}

                        {(showFeature && !showPlan) && <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Add New Feature</h3></div>
                            </div>
                            <div class="card shadown-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <TextInput label={"Feature"} cols={12} value={feature.feature} onChange={e => setFeature({...feature, feature: e.target.value})} />
                                        <div className="col-md-12">
                                            <button className="btn btn-primary" disabled={isLoading} onClick={saveFeature}>{isLoading ? 'Saving...' : 'Save Feature'}</button>
                                            <button className="btn btn-outline-danger ml10" onClick={handleFeatureCancel}>Cancel</button>
                                        </div>
                                    </div>
                                    <div class="row g-3 gx-gs mt-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. #</th>
                                                    <th>Feature</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {plan.plan_features?.map((plan_feature, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{ index + 1 }</td>
                                                            <td>
                                                                {plan_feature.feature}
                                                            </td>
                                                            <td>
                                                                <button onClick={() => editFeature(plan_feature)} className="btn btn-outline-primary btn-sm ml5">
                                                                    <em class="icon ni ni-edit"></em>
                                                                </button>
                                                                <button onClick={() => deleteFeature(plan_feature.id)} className="btn btn-outline-danger btn-sm ml5">
                                                                    <em className="icon ni ni-trash"></em>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

            </div>}
            </div>
        </div>
    </div>
  )
}
