import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const CountrySelector = ({ onChange }) => {
    const [countryOptions, setCountryOptions] = useState([]);

    const getCountries = () => {
        axios.get('https://restcountries.com/v3.1/all')
            .then((response)=>{
                // Extract necessary data and format it correctly
                const countries = response.data.map(country => ({
                    value: country.name.common, // Use country name as value
                    label: country // Use country name as label
                }));
                setCountryOptions(countries);
            })
            .catch((error)=>{
                console.log(error);
            });
    }

    useEffect(()=>{
        getCountries();
    }, [])

return (
  <Select
    defaultInputValue='United Kingdom'
    defaultValue={'uk'}
    inputId='country-input'
    options={countryOptions}
    onChange={onChange}
    formatOptionLabel={({ label, value }) => {
        return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{label.name.common}</span>
      </div>
    )}}
  />
)};

export default CountrySelector;
