import { useEffect } from "react";
import useTitle from "../Hooks/useTitle";

const TermsAndConditions = () => {
    useTitle("Privacy Policy");
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    return (
        <main class="nk-pages">
                <div className="nk-mask bg-gradient-a"></div>
            <section class="section section-0 has-shape mt-50 mb-5">
                <div class="container">
                    <div class="text-center mb-6">
                        <h6 class="overline-title text-primary">EComEmail.AI</h6>
                        <h1 class="title">Terms & Conditions</h1>
                    </div>
                    <div
                        name="termly-embed"
                        data-id="254bd051-0d67-4c2c-adab-2ffa63e34b91"
                        data-type="iframe"
                    ></div>
                </div>
            </section>
        </main>
    )
}

export default TermsAndConditions;