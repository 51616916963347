import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import moment from 'moment';
import Moment from "react-moment";
import { useMatch } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";

const UserLayout = () => {
    const match = useMatch('/user*'); // Use '*' to match all routes
    const location = useLocation();
    const navigate = useNavigate();
    const [activeLink, setActiveLink] = useState(0);

    const [showMobileNav, setShowMobileNav] = useState(false);
    const [isCompact, setIsCompact] = useState(false);

    const logout = (e) => {
        e.preventDefault();
        Helpers.toast("success", "Logged out successfully");
        localStorage.clear();
        navigate('/');
    }
    
    useEffect(() => {
        // This function will be executed whenever the route changes
        if (match) {
            const pathname = match.pathname;
      
            if (pathname === '/user/dashboard') {
              setActiveLink(1);
            } else if (pathname === '/user/templates-library') {
              setActiveLink(2);
            } else if (pathname === '/user/chat-history') {
              setActiveLink(3);
            } else if (pathname.startsWith('/user/chat/')) {
              setActiveLink(3);
            } else if (pathname.startsWith('/user/prompt-questions/')) {
               setActiveLink(2);
            } else if (pathname.startsWith('/user/pricing-plans')) {
               setActiveLink(4);
            } else if (pathname.startsWith('/user/subscribe-plan/')) {
               setActiveLink(4);
            }else{
                setActiveLink(0)
            }

            console.log(match.pathname , " ", activeLink)
          }
      }, [match]); // The effect depends on the match object    

    useEffect(() => {
        Helpers.toggleCSS();
        setShowMobileNav(false);
    }, [location.pathname]);
    
    
    return (
        <div class="nk-app-root" data-sidebar-collapse="lg">
            <div class="nk-main">
                {showMobileNav && <div onClick={() => setShowMobileNav(false)} className="sidebar-overlay"></div>}
                {/* Navbar */}
                <div class={`nk-sidebar nk-sidebar-fixed ${isCompact && 'is-compact'} ${showMobileNav && 'sidebar-active' }`} id="sidebar">
                    <div class="nk-compact-toggle">
                        <button onClick={() => setIsCompact(!isCompact)} class="btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3">
                            <em class={`icon off ni ${ isCompact ? 'ni-chevron-right' : 'ni-chevron-left' }`}></em>
                        </button>
                    </div>
                        <IoMdClose 
                                className="mx-2 mt-2 fs-3 mobile-view-only" 
                                style={{display: showMobileNav ? 'block': "none",alignSelf: "end", right: '0%', position: "relative"}}
                                onClick={()=>setShowMobileNav(!showMobileNav)}
                                ></IoMdClose>
                    <div class="nk-sidebar-element nk-sidebar-head">
                        <div class="nk-sidebar-brand">
                            <a href="/" class="logo-link">
                                <div class="logo-wrap">
                                    <img class="logo-img logo-light" src="/logo-dashboard-white.png" alt="" />
                                    <img class="logo-img logo-dark" src="/logo-dashboard-white.png" alt="" />
                                    <img class="logo-img logo-icon compact-logo" src="/favicon-white.png" alt="" />
                                </div>
                            </a>
                            {/* <button onClick={() => setShowMobileNav(true)} class="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"><X size={26} color="white" /></button> */}
                        </div>
                    </div>
                    <div class="nk-sidebar-element nk-sidebar-body">
                        <div class="nk-sidebar-content h-100" data-simplebar>
                            <div class="nk-sidebar-menu w-100" style={{padding: "0 0 0.75rem 0"}}>
                                <ul class="nk-menu" style={{padding: "0"}}>
                                    <li class="nk-menu-item" 
                                    style={activeLink === 1 ? {borderTop: "0.5px solid rgba(166,166,167,0.41)", borderBottom: "0.5px solid rgba(166,166,167,0.41)"} : {}}
                                    >
                                        <Link to="/user/dashboard" class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span><span class="nk-menu-text">Dashboard</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item"
                                    style={activeLink === 2 ? {borderTop: "0.5px solid rgba(166,166,167,0.41)", borderBottom: "0.5px solid rgba(166,166,167,0.41)"} : {}}>
                                        <Link to="/user/templates-library" class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-layers"></em></span><span class="nk-menu-text">Templates Library</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item"
                                    style={activeLink === 3 ? {borderTop: "0.5px solid rgba(166,166,167,0.41)", borderBottom: "0.5px solid rgba(166,166,167,0.41)"} : {}}
                                    >
                                        <Link to="/user/chat-history" class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-clock"></em></span><span class="nk-menu-text">History</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item"
                                    
                                    style={activeLink === 4 ? {borderTop: "0.5px solid rgba(166,166,167,0.41)", borderBottom: "0.5px solid rgba(166,166,167,0.41)"} : {}}
                                    >
                                        <Link to={'/user/pricing-plans'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-sign-usdc"></em></span><span class="nk-menu-text">Pricing Plans</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <a href="#!" onClick={logout} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-signout"></em></span><span class="nk-menu-text">Sign Out</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="nk-sidebar-element nk-sidebar-footer">
                        <div class="nk-sidebar-footer-extended pt-3">
                            <div class="border border-primary rounded-3">
                                <div class="px-3 py-2 border-light rounded-top-3">
                                    <div class="d-flex flex-wrap align-items-center justify-content-between">
                                        <h6 class="lead-text color-primary">{ Helpers.authUser.plan_name }</h6>
                                        <Link class="link color-white" to={'/user/pricing-plans'}><em class="ni ni-spark-fill icon text-warning"></em><span>Upgrade</span></Link>
                                    </div>
                                    <h6 class="lead-text mt-2"><span className="color-white">Expire On: </span><Moment className="color-primary" date={Helpers.authUser.expire_date} format="MMM Do YYYY" /></h6>
                                </div>
                                <Link class="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3" to={'/user/profile'}>
                                    <div class="media-group">
                                        <div class="media media-sm media-middle media-circle text-bg-primary"><img className="chat-avatar" src={Helpers.serverImage(Helpers.authUser.profile_pic)} alt="" /></div>
                                        <div class="media-text">
                                            <h6 class="fs-6 mb-0 color-white">{ Helpers.authUser.name }</h6>
                                        </div>
                                        <em class="icon ni ni-chevron-right ms-auto ps-1 color-white"></em>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nk-wrap">
                    <div class="nk-header nk-header-fixed">
                        <div class="container-fluid">
                            <div class="nk-header-wrap">
                                <div class="nk-header-logo ms-n1">
                                    <div class="nk-sidebar-toggle me-1">
                                        <button class="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none" onClick={() => setShowMobileNav(true)}><em class="icon ni ni-menu"> </em></button>
                                        <button onClick={() => setShowMobileNav(true)} class="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"><em class="icon ni ni-menu"> </em></button>
                                    </div>
                                    <a href="index-2.html" class="logo-link">
                                        <div class="logo-wrap">
                                            <img class="logo-img logo-light" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                            <img class="logo-img logo-dark" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                            <img class="logo-img logo-icon" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                        </div>
                                    </a>
                                </div>
                                <div class="nk-header-tools">
                                    <ul class="nk-quick-nav ms-2">
                                        <li class="dropdown d-inline-flex">
                                            <Link class="d-inline-flex" to={'/user/profile'}>
                                                <div class="media media-sm media-middle media-circle text-bg-primary"><img className="chat-avatar" src={Helpers.serverImage(Helpers.authUser.profile_pic)} alt="" /></div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                    {(!location.pathname.includes('/chat')) && <div class="nk-footer">
                        <div class="container-xl">
                            <div class="d-flex align-items-center flex-wrap justify-content-between mx-n3">
                                <div class="nk-footer-links px-3">
                                    <ul class="nav nav-sm">
                                        <li class="nav-item"><Link class="nav-link" to="/">Home</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/user/pricing-plans">Pricing</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/privacy-policy">Privacy Policy</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/cookie-policy">Cookie Policy</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/terms-and-conditions">Terms & Conditions</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/disclaimer">Disclaimer</Link></li>
                                    </ul>
                                </div>
                                <div class="nk-footer-copyright fs-6 px-3">&copy; 2023 All Rights Reserved to <Link to="/">eComEmail.AI</Link>.</div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default UserLayout;