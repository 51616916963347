import React from 'react'
import styles from '../Assets/pricing-plan.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InputWithIcon({ placeholder, type, faIcon, pClass, value, onChange, onBlur }) {
  return (
    <div className={pClass}>
        <div className={`${styles['right-inner-addon']} ${styles['input-container']}`}
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}
        >
            {/* <i className={`${faIcon} ${styles['i-custom-pricing']}`}></i> */}
            <FontAwesomeIcon icon={faIcon} className={`${styles['i-custom-pricing']}`}/>
            <input
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            type={`${type}`}
            className={`form-control ${styles.input_pricing_custom} ${styles['input-custom-pricing']}`}
            placeholder={`${placeholder}`}
            required
            />
        </div>
    </div>
  )
}
