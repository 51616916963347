import axios from 'axios';
import {BrowserRouter , Routes , Route, Navigate} from 'react-router-dom'
import Home from './App/Screens/Home';
import Layout from './App/Screens/Layout';
import './App.css';
import Login from './App/Screens/Auth/Login';
import RequestRegister from './App/Screens/User/Screens/RequestRegister';
import Register from './App/Screens/Auth/Register';
import UserLayout from './App/Screens/User/Layout';
import UserDashboard from './App/Screens/User/Screens/Dashboard';
import Verify from './App/Screens/Auth/Verify';
import Helpers from './App/Config/Helpers';
import AdminLayout from './App/Screens/Admin/Layout';
import AdminDashboard from './App/Screens/Admin/Screens/Dashboard';
import AdminCategories from './App/Screens/Admin/Screens/Categories';
import AdminPromptsMain from './App/Screens/Admin/Screens/PromptsMain';
import AdminPromptQuestions from './App/Screens/Admin/Screens/PromptQuestions';
import PromptTesting from './App/Screens/Admin/Screens/PromptTesting';
import AdminInstructions from './App/Screens/Admin/Screens/Instructions';
import Chatbot from './App/Screens/User/Screens/Chatbot';
import TemplatesLibrary from './App/Screens/User/Screens/TemplatesLibrary';
import UserPromptQuestions from './App/Screens/User/Screens/Questions';
import AdminButtons from './App/Screens/Admin/Screens/Buttons';
import ChatHistory from './App/Screens/User/Screens/History';
import AdminUsers from './App/Screens/Admin/Screens/Users';
import AdminChatHistory from './App/Screens/Admin/Screens/ChatHistory';
import SingleChat from './App/Screens/Admin/Screens/SingleChat';
import AdminChatHistoryUser from './App/Screens/Admin/Screens/ChatHistoryUser';
import UserPricingPlans from './App/Screens/User/Screens/PricingPlans';
import AdminStripeDashboard from './App/Screens/Admin/Screens/Products';
import PaymentScreen from './App/Screens/User/Screens/PaymentScreen';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ForgotPassword from './App/Screens/Auth/ForgotPassword';
import VerifyForgotPassword from './App/Screens/Auth/VerifyForgot';
import RecoverPassword from './App/Screens/Auth/RecoverPassword';
import UserProfile from './App/Screens/User/Screens/UserProfile';
import DragDrop from './App/Screens/Admin/Screens/DragDrop';
import PrivacyPolicy from './App/Screens/PrivacyPolicy';
import CookiePolicy from './App/Screens/CookiePolicy';
import TermsAndConditions from './App/Screens/TermsAndConditions';
import Disclaimer from './App/Screens/Disclaimer';
import UseCases from './App/Screens/UseCases';
import AdminPricingPlans from './App/Screens/Admin/Screens/PricingPlans';
import CustomPricingPlans from './App/Screens/User/Screens/CustomPricingPlans';
import RegisteredUser from './App/Screens/Auth/Registered';
import ListsActions from './App/Screens/Admin/Screens/ListsActions';
import AdminFreePlan from './App/Screens/Admin/Screens/FreePlan';
import Action from './App/Screens/Admin/Screens/Action';
import Content from './App/Screens/Admin/Screens/Content';
import Welcome from './App/Screens/User/Screens/Welcome';
import SpecialUser from './App/Screens/Admin/Screens/SpecialUser';
import { useState, useEffect } from 'react';
import Starter from './App/Screens/Starter';
import AdminTrialPlans from './App/Screens/Admin/Screens/TrialPlans.js';
import Trial from './App/Screens/Register And Activation/Trial.js'
import Settings from './App/Screens/Admin/Screens/Settings';

const stripePromise = loadStripe('pk_test_51O9b0oJVi3wqPduPwga8kOEivIqTmn5t6UFj5VRrP2CWqkN4d4sPFBltB7AGz1s7pg9rxXAgrnfkeMz5MpRdSur500rGXqxQxo');
// const stripePromise = loadStripe('pk_test_51Of0LpKDP52BYTYxWwUCzLvfCo67FANYIyavru2i87PwCdqh2t2phT5SVLf4SxgPWIkSs9KkeVgwa8sERf1vxXDG00YO5EaLO8');

const Auth = ({children, isAuth = true, isAdmin = false}) => {
  let user = Helpers.getItem("user", true);
  let token = Helpers.getItem("token");
  let loginTime = Helpers.getItem("loginTimestamp");
  let currentTime = new Date().getTime();
  let minutesPassed = Math.floor((currentTime - loginTime) / (1000 * 60));

  // Check for session expiration
  if (loginTime && minutesPassed > 120) {
    localStorage.clear();
    Helpers.toast("error", "Session expired. Login again to continue");
    return <Navigate to="/login" />;
  } 
  // For protected routes
  else if (isAuth) {
    if (!user || !token) {
      Helpers.toast("error", "Please login to continue");
      return <Navigate to="/login" />;
    }

    // Ensure only admins can access admin routes
    if (isAdmin && user.user_type !== 1) {
      Helpers.toast("error", "Access denied. Only admin allowed.");
      return <Navigate to="/user/dashboard" />;
    }

    // Ensure admins cannot access user routes
    if (!isAdmin && user.user_type === 1) {
      Helpers.toast("error", "Access denied. Admins cannot access user routes.");
      return <Navigate to="/admin/dashboard" />;
    }

    return children;
  } 
  // For non-protected routes like /login
  else {
    if (user && token) {
      if (user.user_type === 1) {
        return <Navigate to="/admin/dashboard" />;
      } else {
        return <Navigate to="/user/dashboard" />;
      }
    }
    return children;
  }
}


const App = () => {

  const [url, setURL] = useState('');

  const getUrl = () => {
    axios.get(`${Helpers.apiUrl}special/get`, Helpers.authHeaders).then((response)=>{
        setURL(response.data.special.url);
    }).catch(err=>{
        Helpers.toast('error', err.message);
        console.log(err)
    })
  }



  useEffect(()=>{
    getUrl();
  },[])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/thank-you' element={<Welcome />}></Route>
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/cookie-policy' element={<CookiePolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/use-cases' element={<UseCases />} />
          <Route path='/login' element={<Auth isAuth={false}><Login /></Auth>} />
          <Route path='/register' element={<Auth isAuth={false}><RequestRegister /></Auth>} />
          <Route path='/ecomemail/register' element={<Register />} />
          <Route path='/verify-email' element={<Auth isAuth={false}><Verify /></Auth>} />
          <Route path='/forgot-password' element={<Auth isAuth={false}><ForgotPassword /></Auth>} />
          <Route path='/verify-email-password' element={<Auth isAuth={false}><VerifyForgotPassword /></Auth>} />
          <Route path='/recover-password' element={<Auth isAuth={false}><RecoverPassword /></Auth>} />
          <Route path='/welcome' element={<Auth isAuth={false}><RegisteredUser /></Auth>} />
          <Route path={`${url}`} element={
            <Elements stripe={stripePromise}>
              <Starter/>
              </Elements>
          }></Route>
        </Route>

          <Route path={`/starter/trial/:id`} element={
            <Elements stripe={stripePromise}>
              <Trial/>
              </Elements>
          }></Route>
          <Route path={`/register/initiate-membership/:id`} element={
            <Elements stripe={stripePromise}>
              <Trial/>
              </Elements>
          }></Route>

        <Route path='/user' element={<UserLayout />}>
          <Route path='/user/dashboard' element={<Auth><UserDashboard /></Auth>} />
          <Route path='/user/templates-library' element={<Auth><TemplatesLibrary /></Auth>} />
          <Route path='/user/chat-history' element={<Auth><ChatHistory /></Auth>} />
          <Route path='/user/chat/:chatid' element={<Auth><Chatbot /></Auth>} />
          <Route path='/user/prompt-questions/:prompt_id/:prompt_name' element={<Auth><UserPromptQuestions /></Auth>} />
          <Route path='/user/pricing-plans' element={<Auth><CustomPricingPlans /></Auth>} />
          <Route path='/user/profile' element={<Auth><UserProfile /></Auth>} />
          <Route path='/user/subscribe-plan/:plan_id' element={<Auth>
            <Elements stripe={stripePromise}>
              <PaymentScreen />
            </Elements>
          </Auth>} />
        </Route>
        <Route path='/admin' element={<AdminLayout />}>
          <Route path='/admin/dashboard' element={<Auth isAdmin={true}><AdminDashboard /></Auth>} />
          <Route path='/admin/categories' element={<Auth isAdmin={true}><AdminCategories /></Auth>} />
          <Route path='/admin/prompts' element={<Auth isAdmin={true}><AdminPromptsMain /></Auth>} />
          <Route path='/admin/prompt/:prompt_id' element={<Auth isAdmin={true}><PromptTesting /></Auth>} />
          <Route path='/admin/drag-drop' element={<Auth isAdmin={true}><DragDrop /></Auth>} />
          <Route path='/admin/prompt/questions/:prompt_id/:is_adding?' element={<Auth isAdmin={true}><AdminPromptQuestions /></Auth>} />
          <Route path='/admin/instructions' element={<Auth isAdmin={true}><AdminInstructions /></Auth>} />
          <Route path='/admin/buttons' element={<Auth isAdmin={true}><AdminButtons /></Auth>} />
          <Route path='/admin/users' element={<Auth isAdmin={true}><AdminUsers /></Auth>} />
          <Route path='/admin/chat-history' element={<Auth isAdmin={true}><AdminChatHistory /></Auth>} />
          <Route path='/admin/chat/:chatid' element={<Auth isAdmin={true}><SingleChat /></Auth>} />
          <Route path='/admin/chats/user/:user_id' element={<Auth isAdmin={true}><AdminChatHistoryUser /></Auth>} />
          <Route path='/admin/stripe-products' element={<Auth isAdmin={true}><AdminStripeDashboard /></Auth>} />
          <Route path='/admin/pricing-plans' element={<Auth isAdmin={true}><AdminPricingPlans /></Auth>} />
          <Route path='/admin/lists-and-actions' element={<Auth isAdmin={true}><ListsActions /></Auth>} />
          <Route path='/admin/action-list' element={<Auth isAdmin={true}><Action /></Auth>} />
          <Route path='/admin/free-plan' element={<Auth isAdmin={true}><AdminFreePlan></AdminFreePlan></Auth>}></Route>
          <Route path='/admin/content' element={<Auth isAdmin={true}><Content></Content></Auth>}></Route>
          <Route path='/admin/starter-plan' element={<Auth isAdmin={true}><SpecialUser/></Auth>}></Route>
          <Route path='/admin/trial-plan' element={<Auth isAdmin={true}><AdminTrialPlans/></Auth>}></Route>
          <Route path='/admin/settings' element={<Auth isAdmin={true}><Settings/></Auth>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;