import { useEffect, useState } from "react";
import useTitle from "../../../Hooks/useTitle";
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import PageLoader from "../../../Components/Loader/PageLoader";
import SelectInput from "../../../Components/Select";

const ListsActions = () => {
    useTitle("Lists & Actions");

    const defaultActions = [
    ];

    const defaultNewAction = {
        action: "",
        list_id: "",
        list_name: "",
    };

    const [lists, setLists] = useState([]);
    const [actions, setActions] = useState(defaultActions);
    const [allActions, setAllActions] = useState([]);
    const [newAction, setNewAction] = useState(defaultNewAction);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [showActions, setShowActions] = useState(false);
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    const getPlans = () => {
        axios.get(`${Helpers.apiUrl}plans/all`, Helpers.authHeaders).then(response => {
            const allPlans = response.data.plans;
            allPlans.forEach(plan => {
                setActions(prevPlans => [...prevPlans, `${plan.plan_name} Activation`]);
            });
        });
    }

    const getLists = () => {
        setPageLoading(true);
        axios.get(`${Helpers.apiUrl}lists/all`, Helpers.authHeaders).then(response => {
            setLists(response.data);
            getPlans();
            setPageLoading(false);
        });
    }

    const handleListSelection = e => {
        const list_id = e.target.value;
        const list_name = lists.find(l => l.id === list_id).name;
        setNewAction(prevAction => ({
            ...prevAction,
            list_name: list_name,
            list_id: list_id
        }));
    }

    const createAction = () => {
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}actions/create`, newAction, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setAllActions(response.data.actions);
            setNewAction(defaultNewAction);
            setIsLoading(false);
        }).catch(error => {
            setErrors(error.response.data.errors || {});
            Helpers.toast("error", error.response.data.message);
            setIsLoading(false);
        });
    }

    const getActions = () => {
        setPageLoading(true)
        axios.get(`${Helpers.apiUrl}action-list/get`, Helpers.authHeaders).then(response => {
                response.data.action.forEach((action)=>{
                    defaultActions.push(action.name)
                })
                setPageLoading(false)
        });
    }

    const getAllActions = () => {
        axios.get(`${Helpers.apiUrl}actions/all`, Helpers.authHeaders).then(response => {
            setAllActions(response.data);
        });
    }

    const handleDeleteList = (id) => {
        setIsLoading(true)
        axios.post(`${Helpers.apiUrl}actions/delete/${id}`, {}, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setAllActions(response.data.actions)            
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
    });
    }

    useEffect(() => {
        getLists();
        getAllActions();
        getActions();
    }, []);

    return (
        <div class="nk-content">
            <div class="container-xl">
                <div class="nk-content-inner">
                    {pageLoading ? <PageLoader /> : <div class="nk-content-body">
                        <div class="nk-block-head nk-page-head">
                            <div class="nk-block-head-between">
                                <div class="nk-block-head-content">
                                    <h2 class="display-6">Lists & Actions</h2>
                                    <p>Manage Active Campaign Lists & Actions</p>
                                </div>
                                {!showActions && <button className="btn btn-primary" onClick={() => setShowActions(true)}>View Actions</button>}
                                {showActions && <button className="btn btn-primary" onClick={() => setShowActions(false)}>Back to Lists</button>}
                            </div>
                        </div>
                        {showActions && <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Create New Action</h3>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <SelectInput 
                                            label={"Choose Action"} 
                                            value={newAction.action} 
                                            onChange={e => setNewAction({...newAction, action: e.target.value})}
                                            error={errors.action}
                                            options={actions}
                                        />
                                        <SelectInput 
                                            label={"Choose List"} 
                                            value={newAction.list_id} 
                                            onChange={handleListSelection}
                                            error={errors.list_id}
                                            options={lists}
                                            isObject={true}
                                            optionLabel={'name'}
                                            optionValue={'id'}
                                        />
                                        <div className="col-12">
                                            <button onClick={createAction} disabled={isLoading} className="btn btn-primary">{!isLoading ? 'Create Action' : 'Please wait...'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {showActions && <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">All Actions</h3></div>
                            </div>
                            <div class="card shadow-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <div className="col-md-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. #</th>
                                                        <th>List Name</th>
                                                        <th>Action</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allActions.map((action, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{ index + 1 }</td>
                                                                <td>{ action.list_name }</td>
                                                                <td>{ action.action }</td>
                                                                <td>                                                                        <button className="btn btn-outline-danger btn-sm " 
                                                                onClick={()=>handleDeleteList(action.id)}>
                                                                    <em class="icon ni ni-trash"></em><span>Delete</span>
                                                                </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {!showActions && <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Active Campaign Lists</h3></div>
                            </div>
                            <div class="card shadow-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <div className="col-md-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. #</th>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists.map((list, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{ index + 1 }</td>
                                                                <td>{ list.id }</td>
                                                                <td>{ list.name }</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ListsActions;