import React, {useState, useEffect} from 'react'
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import TextInput from "../../../Components/Input";
import ImageInput from "../../../Components/ImageInput";
import { Accordion, Card, Button, Modal, Toast  } from 'react-bootstrap';
import useTitle from '../../../Hooks/useTitle';
import PageLoader from "../../../Components/Loader/PageLoader";
import SelectInput from "../../../Components/Select"
import { Player, ControlBar } from 'video-react';

export default function Content() {
    useTitle("Content");
    const defaultHeroContent = {
        welcome: "",
        heading: "",
        highlighted_heading: "",
        paragraph: "",
        media_type: "",
        video: null,
        image: null
    }

    const defaultFeaturesContent = {
        icon_1: null,
        heading_1: "",
        text_1: "",
        icon_2: null,
        heading_2: "",
        text_2: "",
        icon_3: null,
        heading_3: "",
        text_3: "",
    }

    const defaultOptimizationContent = {
        heading: "",
        paragraph: "",
        card_heading_1: "",
        card_text_1: "",
        card_heading_2: "",
        card_text_2: "",
        card_heading_3: "",
        card_text_3: "",
    }

    const defaultStratergiesContent = {
        heading: "",
        paragraph: "",
        marketing_prompts: null
    }

    
    const defaultSingleStratergy = {
        iconClass: "",
        heading: "",
        paragraph: ""
    }
    
    const defaultHowItWorksContent = {
        heading: "",
        paragraph: "",
        working_stratergies: null
    }

    const defaultSingleWorkContent = {
        no: "",
        img: "",
        heading: "",
        paragraph: ""
    }
    
    const [prompts, setPrompts] = useState([]);
    const [features, setFeatures] = useState(defaultFeaturesContent);
    const [optimizationContent, setOptimizationContent] = useState(defaultOptimizationContent);
    const [strategies, setStratergies] = useState(defaultStratergiesContent);
    const [strategiesList, setStratergiesList] = useState([]);
    const [heroContentDisplay, setHeroContentDisplay] = useState(false);
    const [featuresContentDisplay, setFeaturesContentDisplay] = useState(false);
    const [optimizationContentDisplay, setOptimizationContentDisplay] = useState(false)
    const [strategiesContentDisplay, setStratergiesContentDisplay] = useState(false)
    const [howitworksContentDisplay, setHowItWorksContentDisplay] = useState(false)
    const [hero, setHero] = useState(defaultHeroContent);
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoader] = useState(false)
    const [url, setUrl] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [showWorksModal, setShowWorksModal] = useState(false);
    const [addStratergy, setAddStratergy] = useState(defaultSingleStratergy);
    const [howItWorks, setHowItWorks] = useState(defaultHowItWorksContent);
    const [howItWorksList, setHowItWorksList] = useState([]);
    const [howItWorksEdited, setHowItWorksEdited] = useState(false)
    const [howItWorksContent, setHowItWorksContent] = useState(defaultSingleWorkContent)
    const [hasUploaded, setHasUploaded] = useState(false)
    const [editIndex, setEditIndex] = useState(-1);
    const [count, setCount] = useState(0)
    const [selectedMediaType, setSelectedMediaType] = useState('');
    const [images, setImages] = useState([]);
    const [imageModelShow, setImageModelShow] = useState(false);
    const [image,setImage] = useState(null);

    const handleToggleImageModel = () => {
        setImageModelShow(!imageModelShow)
    }

    const handleMediaTypeChange = (event) => {
      setSelectedMediaType(event.target.value);
      setHero({...hero, media_type: event.target.value});
    };

    // Handle Array Of Strategy JSON
    const handleShowStrategyModal = () => {
      setShowModal(true);
    };
  
    const handleCloseStrategyModal = () => {
        setAddStratergy(defaultSingleStratergy);
      setShowModal(false);
    };

    const HandleAddStratergy = () => {
        if(addStratergy.heading == ""){
            Helpers.toast("error", "Heading Cannot be Null")
            return;
        }
        
        if(addStratergy.iconClass == ""){
            Helpers.toast("error", "Icon Cannot be Null")
            return;
        }
        
        if(addStratergy.heading == ""){
            Helpers.toast("error", "Paragraph Cannot be Null")
            return;
        }
        setStratergiesList([...strategiesList, addStratergy])
        handleCloseStrategyModal();
    }

    const handleDeleteStratergy = (headingToRemove) => {
            // Filter out the strategy with the matching heading
            const updatedStrategies = strategiesList.filter(strategy => strategy.heading !== headingToRemove);
            // Update the state with the new array
            setStratergiesList(updatedStrategies);
    }
    
    
    // Handle Array Of How it Works
    const handleShowWorksModel = () => {
        setShowWorksModal(true);
    }

    const handleCloseWorksModel = () => {
        setHowItWorksContent(defaultHowItWorksContent);
        setShowWorksModal(false)
        setHasUploaded(false)
    }

    const HandleAddWork = () => {
        try{
            setIsLoader(true)
            const formData = new FormData();
            formData.append('image', howItWorksContent.img)
            axios.post(`${Helpers.apiUrl}howitworks/upload`, formData,Helpers.authFileHeaders)
            .then(response=>{
                setHowItWorksContent({...howItWorksContent, img: response.data.image.name});
            }).catch(errors=>{
                Helpers.toast('error',"An error Occured, Please Try Again")
                console.log(errors)
                return;
            });
        }catch(error){
            console.log(error)
            return;
        }finally{
            if(howItWorksContent.heading == ""){
                Helpers.toast("error", "Heading Cannot be Null")
                return;
            }
            
            if(howItWorksContent.no == ""){
                Helpers.toast("error", "No Cannot be Null")
                return;
            }
            
            if(howItWorksContent.paragraph == ""){
                Helpers.toast("error", "Paragraph Cannot be Null")
                return;
            }
                setHowItWorksList([...howItWorksList, howItWorksContent])
        }
    }

    const handleEditWork = () => {
            // If the item is found, update its properties
            if (editIndex !== -1) {
              setHowItWorksList(prevList => {
                // Create a new array with the updated item
                const updatedList = [...prevList];
                updatedList[editIndex] = {
                  ...updatedList[editIndex],
                  // Update other properties with the values from howItWorksContent
                   no: howItWorksContent.no,
                   heading: howItWorksContent.heading,
                   paragraph: howItWorksContent.paragraph,
                   img: howItWorksContent.img,
                  // Add more properties as needed
                };
                setHowItWorksList(updatedList);
            });
        }
        setHowItWorksEdited(false)
        handleCloseWorksModel();
    }

    const handleHomeEdit = () => {
        setUrl(`${Helpers.apiUrl}hero/update`);
        setHeroContentDisplay(true)
    }

    const handleFeaturesEdit = () => {
        setUrl(`${Helpers.apiUrl}features/update`);
        setFeaturesContentDisplay(true)
    }

    const handleOptimizationEdit = () => {
        setUrl(`${Helpers.apiUrl}optimisation/update`);
        setOptimizationContentDisplay(true)
    }

    const handleStratergiesEdit = () => {
        setUrl(`${Helpers.apiUrl}strategies/update`)
        setStratergiesContentDisplay(true)
    }
    
    const handleHowItWorksEdit = () => {
        setUrl(`${Helpers.apiUrl}howitworks/update`);
        setHowItWorksContentDisplay(true)
    }
    
    const handleUploadImage = () => {
        const formData = new FormData();
        formData.append('image', image);
        axios.post(`${Helpers.apiUrl}howitworks/upload`, formData,Helpers.authFileHeaders)
            .then(response=>{
                Helpers.toast('success', response.data.message);
                setImageModelShow(false);
                setCount(count + 1);
            }).catch(errors=>{
                Helpers.toast('error',errors.message)
                console.log(errors)
                return;
            });
    }

    const handleCancel = () => {
        setUrl('');
        setHasUploaded(false)
        setHeroContentDisplay(false);
        setFeaturesContentDisplay(false);
        setOptimizationContentDisplay(false);
        setStratergiesContentDisplay(false);
        setHowItWorksContentDisplay(false)
    }

    const submitHeroContent = () => {
        const formData = new FormData();
        formData.append('welcome', hero.welcome);
        formData.append('heading', hero.heading);
        formData.append('highlighted_heading', hero.highlighted_heading);
        formData.append('paragraph', hero.paragraph);
        formData.append('media_type', hero.media_type)
        formData.append('video', hero.video);
        formData.append('image', hero.image);
        axios.post(url, formData, Helpers.authFileHeaders).then(response => {
            setHero(response.data.content)
            handleCancel();
            Helpers.toast("success", "Content Uploaded Successfully")
            setCount(count + 1);
        }).catch(error => {
            setErrors(error.response.data.errors);
            Helpers.toast('error', error.response.data.message)
            setCount(count + 1);
        })
    }

    const submitFeaturesContent = () => {
        const formData = new FormData();
        formData.append('icon_1', features.icon_1);
        formData.append('heading_1', features.heading_1);
        formData.append('text_1', features.text_1);
        formData.append('icon_2', features.icon_2);
        formData.append('heading_2', features.heading_2);
        formData.append('text_2', features.text_2);
        formData.append('icon_3', features.icon_3);
        formData.append('heading_3', features.heading_3);
        formData.append('text_3', features.text_3);
        axios.post(url, formData, Helpers.authFileHeaders).then(response => {
            setFeatures(response.data.content)
            handleCancel();
            Helpers.toast("success", "Content Uploaded Successfully")
            setCount(count + 1);
        }).catch(error => {
            setErrors(error.response.data.errors);
            Helpers.toast('error', error.response.data.message)
            setCount(count + 1);
        })
    }


    const submitOptimizationContent = () => {
        const formData = new FormData();
        formData.append('heading', optimizationContent.heading);
        formData.append('paragraph', optimizationContent.paragraph);
        formData.append('card_heading_1', optimizationContent.card_heading_1);
        formData.append('card_text_1', optimizationContent.card_text_1);
        formData.append('card_heading_2', optimizationContent.card_heading_2);
        formData.append('card_text_2', optimizationContent.card_text_2);
        formData.append('card_heading_3', optimizationContent.card_heading_3);
        formData.append('card_text_3', optimizationContent.card_text_3);
        axios.post(url, formData, Helpers.authFileHeaders).then(response => {
            setOptimizationContent(response.data.content)
            handleCancel();
            Helpers.toast("success", "Content Uploaded Successfully")
            setCount(count + 1);
        }).catch(error => {
            setErrors(error.response.data.errors);
            Helpers.toast('error', error.response.data.message)
            setCount(count + 1);
        })
    }

    const submitStratergiesContent = () => {
        const formData = new FormData();
        formData.append('heading', strategies.heading);
        formData.append('paragraph', strategies.paragraph);
        formData.append('marketing_prompts', JSON.stringify({ features: strategiesList }));
        axios.post(url, formData, Helpers.authFileHeaders).then(response => {
            setStratergies(response.data.content);
            setStratergiesList(JSON.parse(response.data.content.marketing_prompts).features);
            handleCancel();
            Helpers.toast("success", "Content Uploaded Successfully")
            setCount(count + 1);
        }).catch(error => {
            setErrors(error.response.data.errors);
            Helpers.toast('error', error.response.data.message)
            setCount(count + 1);
        })
    }

    const submitHowItWorksContent = () => {
        const formData = new FormData();
        formData.append('heading', howItWorks.heading);
        formData.append('paragraph', howItWorks.paragraph);
        formData.append('working_stratergies', JSON.stringify({ working: howItWorksList }));
        axios.post(url, formData, Helpers.authFileHeaders).then(response => {
            // setHowItWorks(response.data.content);
            // setHowItWorksList(JSON.parse(response.data.content.working_stratergies).working);
            handleCancel();
            setHowItWorksContentDisplay(false)
            Helpers.toast("success", "Content Uploaded Successfully")
            setCount(count + 1);
        }).catch(error => {
            setHowItWorksContentDisplay(false)
            setErrors(error.response.data.errors);
            Helpers.toast('error', error.response.data.message)
            setCount(count + 1);
        })
    }

    const handleEditHowItWorks = (e) => {
        setHowItWorksContent({heading: e.heading,no: e.no, img: e.img, paragraph: e.paragraph});
        const indexToEdit = howItWorksList.findIndex(item => item.heading === e.heading);
        setEditIndex(indexToEdit)
        setHowItWorksEdited(true)
        setShowWorksModal(true)
    }

    const handleDeleteHowItWorks = (heading) => {
        // Filter out the strategy with the matching heading
        const updatedWorking = howItWorksList.filter(work => work.heading !== heading);
        // Update the state with the new array
        setHowItWorksList(updatedWorking);
    }

    const handleStrategyChange = (index, field, value) => {
        const updatedStrategiesList = [...strategiesList];
        updatedStrategiesList[index] = {
          ...updatedStrategiesList[index],
          [field]: value,
        };
        setStratergiesList(updatedStrategiesList);
      }; 

    const getHome = () => {
        setIsLoader(true)
        axios.get(`${Helpers.apiUrl}hero/all`).then(response => {
            setHero(response.data.content);
            setIsLoader(false);         
        })
    }

    const getFeatures = () => {
        setIsLoader(true)
        axios.get(`${Helpers.apiUrl}features/all`).then(response => {
            setFeatures(response.data.content);
            setIsLoader(false)
        })
    }

    const getOptimizationContent = () => {
        setIsLoader(true)
        axios.get(`${Helpers.apiUrl}optimisation/all`).then(response => {
            setOptimizationContent(response.data.content);
            setIsLoader(false)
        })
    }

    const marketingStratergies = () => {
        setIsLoader(true)
        axios.get(`${Helpers.apiUrl}strategies/all`).then(response => {
            setStratergies(response.data.content);
            setStratergiesList(JSON.parse(response.data.content.marketing_prompts).features);
            setIsLoader(false)
        })
    }

    const WorksContent = () => {
        setIsLoader(true)
        try{
            axios.get(`${Helpers.apiUrl}howitworks/all`).then(response => {
                setHowItWorks(response.data.content);
                setHowItWorksList(JSON.parse(response.data.content.working_stratergies).working);
                setIsLoader(false)
                // Assuming response.data.images contains the object {"2": "a.png", "3": "b.png", "4": "c.png"}
                let imagesArray = [];

                for (let key in response.data.images) {
                    if (response.data.images.hasOwnProperty(key)) {
                        imagesArray.push(response.data.images[key]);
                    }
                }

                setImages(imagesArray);
            })
        }catch(error){
            Helpers.toast("error","An Error Occured");
            console.log(error)
        }
    }

    useEffect(() => {
        getHome();
        getFeatures();
        getOptimizationContent();
        marketingStratergies();
        WorksContent();
    }, [count]);
  return (
    <div class="nk-content">
      <div class="container-xl">
          <div class="nk-content-inner">
            {isLoading ? <PageLoader></PageLoader> :
              <div class="nk-content-body">
                  <div class="nk-block-head nk-page-head">
                      <div class="nk-block-head-between">
                          <div class="nk-block-head-content">
                              <h2 class="display-6">Content</h2>
                              <p>Manage the content for dashboard</p>
                          </div>
                      </div>
                  </div>
                  

                  {(!heroContentDisplay && !featuresContentDisplay && !optimizationContentDisplay && !strategiesContentDisplay && !howitworksContentDisplay) &&(<div class="card shadown-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. #</th>
                                                    <th>Hero Section</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               <tr>
                                                    {/* <td colSpan={10}>No records found...</td> */}
                                                </tr>
                                                        <tr>
                                                            <td> #1</td>
                                                            <td> Home </td>
                                                            <td> </td>
                                                            <td className="tb-col-end">
                                                                <div>
                                                                    <button  className="btn btn-outline-primary btn-sm ml5" onClick={handleHomeEdit}>
                                                                        <em class="icon ni ni-edit"></em><span className="ml5">Edit</span>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td> #2</td>
                                                            <td> Features </td>
                                                            <td> </td>
                                                            <td className="tb-col-end">
                                                                <div>
                                                                    <button  className="btn btn-outline-primary btn-sm ml5" 
                                                                    onClick={handleFeaturesEdit}
                                                                    >
                                                                        <em class="icon ni ni-edit"></em><span className="ml5">Edit</span>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td> #3</td>
                                                            <td> Optimization Tools </td>
                                                            <td> </td>
                                                            <td className="tb-col-end">
                                                                <div>
                                                                    <button  className="btn btn-outline-primary btn-sm ml5" onClick={handleOptimizationEdit}>
                                                                        <em class="icon ni ni-edit"></em><span className="ml5">Edit</span>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td> #4</td>
                                                            <td> Strategies Content </td>
                                                            <td> </td>
                                                            <td className="tb-col-end">
                                                                <div>
                                                                    <button  className="btn btn-outline-primary btn-sm ml5" onClick={handleStratergiesEdit}>
                                                                        <em class="icon ni ni-edit"></em><span className="ml5">Edit</span>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td> #5</td>
                                                            <td> How it Works </td>
                                                            <td> </td>
                                                            <td className="tb-col-end">
                                                                <div>
                                                                    <button  className="btn btn-outline-primary btn-sm ml5" onClick={handleHowItWorksEdit}>
                                                                        <em class="icon ni ni-edit"></em><span className="ml5">Edit</span>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                   
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>)}

                            {heroContentDisplay && (
                                            <div class="nk-block">
                                            <div class="nk-block-head nk-block-head-sm">
                                                <div class="nk-block-head-content"><h3 class="nk-block-title">Edit Hero Section Content</h3></div>
                                            </div>
                                            <div class="card shadown-none">
                                                <div class="card-body">
                                                    <div class="row g-3 gx-gs">
                                                        <TextInput error={errors.welcome} cols={12} label={"Welcome Text"} value={hero.welcome} onChange={e => setHero({...hero, welcome: e.target.value})} />
                                                        <TextInput error={errors.heading} label={"Heading"} value={hero.heading} onChange={e => setHero({...hero, heading: e.target.value})}/>
                                                        <TextInput error={errors.highlighted_heading} label={"Heading Last Words"} value={hero.highlighted_heading} onChange={e => setHero({...hero, highlighted_heading: e.target.value})} />
                                                        <TextInput isTextArea={true} error={errors.paragraph} label={"Intro Paragraph"} cols={12} value={hero.paragraph} onChange={e => setHero({...hero, paragraph: e.target.value})} />
                                                        
                                                        <div>
                                                        <label>Media Type:</label>
                                                        <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadio1"
                                                            value="video"
                                                            onChange={handleMediaTypeChange}
                                                            checked={selectedMediaType === 'video'}
                                                        />
                                                        <label className="form-check-label" htmlFor="exampleRadio1">
                                                            Video
                                                        </label>
                                                        </div>
                                                        <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadio2"
                                                            value="photo"
                                                            onChange={handleMediaTypeChange}
                                                            checked={selectedMediaType === 'photo'}
                                                        />
                                                        <label className="form-check-label" htmlFor="exampleRadio2">
                                                            Photo
                                                        </label>
                                                        </div>
                                                        </div>

                                                        {selectedMediaType === 'video' &&            
                                                        <div>                                 
                                                        <Player className="w-100 rounded-3"
                                                        autoPlay muted controls={false} fluid={false} height={"auto"} width={"auto"} aspectRatio='16:9'
                                                        src={`${hero.video}`}
                                                        >
                                                            <ControlBar autoHide={true} disableCompletely={true} disableDefaultControls={true} className="my-class" />
                                                        </Player>
                                                        <input type="text" accept="text" className='w-100 border rounded-3 p-2'  placeholder='Upload an Image Link' onChange={e => {setHero({...hero, video: e.target.value})}}/><br/>
                                                        <small className='text-muted'>Image Must be a URL Link which includes .mp4 in the end as an extension</small>
                                                        <label>{errors.video}</label>
                                                        </div>}

                                                        {selectedMediaType === 'photo' && <ImageInput url={Helpers.imgUrl} imgName={hero.image} uploaded={hasUploaded} cols={12} placeholder={hero.image} label={"Current Image"} error={errors.image} 
                                                        onChange={e => {
                                                            setHero({...hero, image: e.target.files[0]})
                                                            setHasUploaded(true)}}></ImageInput>}

                                                        <div className="col-md-12">
                                                            <button className="btn btn-primary" disabled={isLoading} onClick={submitHeroContent}>{isLoading ? 'Saving...' : 'Save'}</button>
                                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}  disabled={isLoading}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )}


                                        {featuresContentDisplay && (
                                            <div class="nk-block">
                                            <div class="nk-block-head nk-block-head-sm">
                                                <div class="nk-block-head-content"><h3 class="nk-block-title">Edit Features Section Content</h3></div>
                                            </div>
                                            <div class="card shadown-none">
                                                <div class="card-body">
                                                    <div class="row g-3 gx-gs">
                                                        <ImageInput url={Helpers.imgUrl} imgName={features.icon_1} imageCols={2} cols={12} placeholder={features.icon_1} label={"Icon"} error={errors.image} onChange={e => setFeatures({...features, icon_1: e.target.files[0]})}></ImageInput>
                                                        <TextInput isTextArea={false} error={errors.paragraph} label={"Heading:"} cols={12} value={features.heading_1} onChange={e => setFeatures({...features, heading_1: e.target.value})} />
                                                        <TextInput isTextArea={false} error={errors.paragraph} label={"Text:"} cols={12} value={features.text_1} onChange={e => setFeatures({...features, text_1: e.target.value})} />
                                                        <ImageInput url={Helpers.imgUrl} imgName={features.icon_2} imageCols={2} cols={12} placeholder={features.icon_1} label={"Icon"} error={errors.image} onChange={e => setFeatures({...features, icon_2: e.target.files[0]})}></ImageInput>
                                                        <TextInput isTextArea={false} error={errors.paragraph} label={"Heading:"} cols={12} value={features.heading_2} onChange={e => setFeatures({...features, heading_2: e.target.value})} />
                                                        <TextInput isTextArea={false} error={errors.paragraph} label={"Text:"} cols={12} value={features.text_2} onChange={e => setFeatures({...features, text_2: e.target.value})} />
                                                        <ImageInput url={Helpers.imgUrl} imgName={features.icon_3} imageCols={2} cols={12} placeholder={features.icon_3} label={"Icon"} error={errors.image} onChange={e => setFeatures({...features, icon_3: e.target.files[0]})}></ImageInput>
                                                        <TextInput isTextArea={false} error={errors.paragraph} label={"Heading:"} cols={12} value={features.heading_3} onChange={e => setFeatures({...features, heading_3: e.target.value})} />
                                                        <TextInput isTextArea={false} error={errors.paragraph} label={"Text:"} cols={12} value={features.text_3} onChange={e => setFeatures({...features, text_3: e.target.value})} />
                                                        <div className="col-md-12">
                                                            <button className="btn btn-primary" disabled={isLoading} onClick={submitFeaturesContent}>{isLoading ? 'Saving...' : 'Save'}</button>
                                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}  disabled={isLoading}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )}

                                        {optimizationContentDisplay && (
                                            <div class="nk-block">
                                            <div class="nk-block-head nk-block-head-sm">
                                                <div class="nk-block-head-content"><h3 class="nk-block-title">Edit Optimization Section Content</h3></div>
                                            </div>
                                            <div class="card shadown-none">
                                                <div class="card-body">
                                                    <div class="row g-3 gx-gs">
                                                        <TextInput isTextArea={false} error={errors.heading} label={"Heading:"} cols={12} value={optimizationContent.heading} onChange={e => setOptimizationContent({...optimizationContent, heading: e.target.value})} />
                                                        <TextInput isTextArea={true} error={errors.heading} label={"Paragraph:"} cols={12} value={optimizationContent.paragraph} onChange={e => setOptimizationContent({...optimizationContent, paragraph: e.target.value})} />
                                                        <TextInput isTextArea={false} error={errors.heading} label={"Card Heading:"} cols={12} value={optimizationContent.card_heading_1} onChange={e => setOptimizationContent({...optimizationContent, card_heading_1: e.target.value})} />
                                                        <TextInput isTextArea={true} error={errors.heading} label={"Card Text:"} cols={12} value={optimizationContent.card_text_1} onChange={e => setOptimizationContent({...optimizationContent, card_text_1: e.target.value})} />
                                                        <TextInput isTextArea={false} error={errors.heading} label={"Card Heading:"} cols={12} value={optimizationContent.card_heading_2} onChange={e => setOptimizationContent({...optimizationContent, card_heading_2: e.target.value})} />
                                                        <TextInput isTextArea={true} error={errors.heading} label={"Card Text:"} cols={12} value={optimizationContent.card_text_2} onChange={e => setOptimizationContent({...optimizationContent, card_text_2: e.target.value})} />
                                                        <TextInput isTextArea={false} error={errors.heading} label={"Card Heading:"} cols={12} value={optimizationContent.card_heading_3} onChange={e => setOptimizationContent({...optimizationContent, card_heading_3: e.target.value})} />
                                                        <TextInput isTextArea={true} error={errors.heading} label={"Card Text:"} cols={12} value={optimizationContent.card_text_3} onChange={e => setOptimizationContent({...optimizationContent, card_text_3: e.target.value})} />
                                                        <div className="col-md-12">
                                                            <button className="btn btn-primary" disabled={isLoading} onClick={submitOptimizationContent}>{isLoading ? 'Saving...' : 'Save'}</button>
                                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}  disabled={isLoading}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )}

                                        {strategiesContentDisplay && (
                                            <div class="nk-block">
                                            <div class="nk-block-head nk-block-head-sm">
                                                <div class="nk-block-head-content"><h3 class="nk-block-title">Edit Strategies Section Content</h3></div>
                                            </div>
                                            <div class="card shadown-none">
                                                <div class="card-body">
                                                    <div class="row g-3 gx-gs">
                                                        <TextInput isTextArea={false} error={errors.heading} label={"Heading:"} cols={12} value={strategies.heading} onChange={e => setStratergies({...strategies, heading: e.target.value})} />
                                                        <TextInput isTextArea={true} error={errors.heading} label={"Paragraph:"} cols={12} value={strategies.paragraph} onChange={e => setStratergies({...strategies, paragraph: e.target.value})} />


                                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        <Button variant="outline-primary" onClick={handleShowStrategyModal}><em class="icon ni ni-plus-circle"></em>Add Strategy</Button>
                                                        </div>

                                                        <Modal show={showModal} onHide={handleCloseStrategyModal}>
                                                            <Modal.Header closeButton>
                                                            <Modal.Title>Add Strategy</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                            <Card.Body>
                                                                <div className="feature feature-inline">
                                                                    <div class="feature-media">
                                                                        <div class="text-gradient-primary fs-2 d-inline-flex text-primary"><em class={addStratergy.iconClass}></em></div>
                                                                    </div>
                                                                    <div className='d-flex flex-row justify-content-between md:flex-column col-md-12'>
                                                                        <TextInput icon={true} iconClass={addStratergy.iconClass} isTextArea={false} label={"Icon Class:"} cols={5} value={addStratergy.iconClass}
                                                                            onChange={(e) => setAddStratergy({...addStratergy, iconClass: e.target.value})} />
                                                                            <div className='col-md-1'></div>
                                                                        <TextInput isTextArea={false} error={errors.heading} label={"Heading:"} cols={6} value={addStratergy.heading}
                                                                            onChange={(e) => setAddStratergy({...addStratergy, heading: e.target.value})} />
                                                                    </div>
                                                                    <div class="feature-text">
                                                                        <TextInput isTextArea={true} className={"round-5"} label={"Paragraph:"} cols={12} value={addStratergy.paragraph}
                                                                            onChange={(e) => setAddStratergy({...addStratergy, paragraph: e.target.value})} />
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                            <Button variant='primary' onClick={HandleAddStratergy}>Add</Button>
                                                            <Button variant="outline-danger" onClick={handleCloseStrategyModal}>
                                                                Close
                                                                </Button>

                                                            {/* Additional buttons or actions if needed */}
                                                            </Modal.Footer>
                                                        </Modal>
                                                            <div className="col-md-12">
                                                                <Accordion>
                                                                    {strategiesList.length > 0 ? strategiesList.map((e, index) => (
                                                                        <Accordion.Item key={index} eventKey={index.toString()} className='my-2'>
                                                                            <Card key={index}>
                                                                                <Card.Header>
                                                                                    <Accordion.Header>{e.heading}</Accordion.Header>
                                                                                </Card.Header>
                                                                                <Accordion.Body>
                                                                                    <Card.Body>
                                                                                        <div className="feature feature-inline">
                                                                                            <div class="feature-media">
                                                                                                <div class="text-gradient-primary fs-2 d-inline-flex text-primary"><em class={e.iconClass}></em></div>
                                                                                            </div>
                                                                                            <div className='d-flex flex-row justify-content-between md:flex-column col-md-12'>
                                                                                                <TextInput icon={true} iconClass={e.iconClass} isTextArea={false} label={"Icon Class:"} cols={5} value={e.iconClass}
                                                                                                    onChange={(e) => handleStrategyChange(index, "iconClass", e.target.value)} />
                                                                                                    <div className='col-md-1'></div>
                                                                                                <TextInput isTextArea={false} label={"Heading:"} cols={6} value={e.heading}
                                                                                                    onChange={(e) => handleStrategyChange(index, "heading", e.target.value)} />
                                                                                            </div>
                                                                                            <div class="feature-text">
                                                                                                <TextInput isTextArea={true} className={"round-5"} label={"Paragraph:"} cols={12} value={e.paragraph}
                                                                                                    onChange={(e) => handleStrategyChange(index, "paragraph", e.target.value)} />
                                                                                            </div>
                                                                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                                                <Button className='my-2 btn btn-md' variant="outline-danger" onClick={() => handleDeleteStratergy(e.heading)}>Delete</Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Card.Body>
                                                                                </Accordion.Body>
                                                                            </Card>
                                                                        </Accordion.Item>
                                                                    ))
                                                                    : 
                                                                    <p>No List available</p>
                                                                    }
                                                                </Accordion>
                                                            </div>
                                                        <div className="col-md-12">
                                                            <button className="btn btn-primary" disabled={isLoading} onClick={submitStratergiesContent}>{isLoading ? 'Saving...' : 'Save'}</button>
                                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}  disabled={isLoading}>Cancel</button>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )}

                                        {howitworksContentDisplay && (
                                            <div class="nk-block">
                                            <div class="nk-block-head nk-block-head-sm">
                                                <div class="nk-block-head-content"><h3 class="nk-block-title">Edit How It Works Section Content</h3></div>
                                            </div>
                                            <div class="card shadown-none">
                                                <div class="card-body">
                                                    <div class="row g-3 gx-gs">
                                                        <div className='d-flex justify-content-end'>
                                                        <Button variant="outline-primary" onClick={handleToggleImageModel}>
                                                            Upload
                                                        </Button>
                                                        <Modal show={imageModelShow} onHide={handleToggleImageModel}>
                                                            <Modal.Header closeButton>
                                                            <Modal.Title>Upload Image</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                            <ImageInput url={Helpers.imgUrl} imgName={howItWorksContent.img} 
                                                                imageCols={12} cols={12} placeholder={howItWorksContent.img} 
                                                                label={"Image"} error={errors.image} uploaded={hasUploaded} 
                                                                onChange={e =>
                                                                {setImage(e.target.files[0])}}
                                                            ></ImageInput>
                                                            </Modal.Body>
                                                            <div className='d-flex flex-row col-md-8 m-2 p-2'>
                                                            <Button variant="outline-primary" className='mx-2' onClick={handleToggleImageModel}>
                                                                Close
                                                            </Button>
                                                            <Button variant="primary" onClick={handleUploadImage}>
                                                                Save Changes
                                                            </Button>
                                                            </div>
                                                        </Modal>
                                                        </div>
                                                        <TextInput isTextArea={false} error={errors.heading} label={"Heading:"} cols={12} value={howItWorks.heading} onChange={e => setHowItWorks({...howItWorks, heading: e.target.value})} />
                                                        

                                                        <Modal show={showWorksModal} onHide={handleCloseWorksModel}>
                                                            <Modal.Header closeButton>
                                                            <Modal.Title>Edit</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                {howItWorksEdited ? 
                                                            <Card.Body>
                                                                <div className="feature feature-inline">
                                                                    <div class="feature-media">
                                                                        {/* Image */}
                                                                        {/* <ImageInput url={Helpers.imgUrl} imgName={howItWorksContent.img} 
                                                                        imageCols={12} cols={12} placeholder={howItWorksContent.img} 
                                                                        label={"Image"} error={errors.image} uploaded={hasUploaded} 
                                                                        onChange={e => 
                                                                        {setHowItWorksContent({...howItWorksContent, img: e.target.files[0]})
                                                                        setHasUploaded(true)}}
                                                                        ></ImageInput> */}
                                                                    </div>
                                                                    <div className='d-flex flex-row justify-content-between md:flex-column col-md-12'>
                                                                        {/* Inputs */}
                                                                        {/* <TextInput value={howItWorksContent.no} isTextArea={false} label={"No:"} cols={6}
                                                                            onChange={(e) => setHowItWorksContent({...howItWorksContent , no: e.target.value})} /> */}
                                                                        <TextInput value={howItWorksContent.heading} isTextArea={false} label={"Heading:"} cols={12}
                                                                            onChange={(e) => setHowItWorksContent({...howItWorksContent , heading: e.target.value})} />
                                                                    </div>
                                                                    <div class="feature-text">
                                                                        {/* Input */}
                                                                        <TextInput value={howItWorksContent.paragraph} isTextArea={true} label={"Paragraph:"} cols={12}
                                                                            onChange={(e) => setHowItWorksContent({...howItWorksContent , paragraph: e.target.value})} />
                                                                    </div>

                                                                    <div className='feature-text'>
                                                                        <SelectInput
                                                                        cols={12}      
                                                                        label={'Image '} 
                                                                        value={howItWorksContent.img} 
                                                                        options={images}
                                                                        onChange={(e) => setHowItWorksContent({...howItWorksContent , img: e.target.value})}
                                                                        />
                                                                        <p className='m-1 text-muted'>Kindly Select the Name of Image</p>
                                                                    </div>
                                                                </div>
                                                            </Card.Body> 
                                                            : 
                                                            <></>
                                                        //     <Card.Body>
                                                        //     <div className="feature feature-inline">
                                                        //         <div class="feature-media">
                                                        //             {/* Image */}
                                                        //             <ImageInput url={Helpers.imgUrl} imgName={howItWorksContent.img} 
                                                        //             imageCols={2} cols={12} placeholder={howItWorksContent.img} 
                                                        //             label={"Image"} error={errors.image}
                                                        //             onChange={e => 
                                                        //             {setHowItWorksContent({...howItWorksContent, img: e.target.files[0]})
                                                        //             setHasUploaded(true)}}
                                                        //             ></ImageInput>
                                                        //         </div>
                                                        //         <div className='d-flex flex-row justify-content-between md:flex-column col-md-12'>
                                                        //             {/* Inputs */}
                                                        //             <TextInput value={howItWorksContent.no} isTextArea={false} label={"No:"} cols={6}
                                                        //                 onChange={(e) => setHowItWorksContent({...howItWorksContent , no: e.target.value})} />
                                                        //             <TextInput value={howItWorksContent.heading} isTextArea={false} label={"Heading:"} cols={6}
                                                        //                 onChange={(e) => setHowItWorksContent({...howItWorksContent , heading: e.target.value})} />
                                                        //         </div>
                                                        //         <div class="feature-text">
                                                        //             {/* Input */}
                                                        //             <TextInput value={howItWorksContent.paragraph} isTextArea={true} label={"Paragraph:"} cols={12}
                                                        //                 onChange={(e) => setHowItWorksContent({...howItWorksContent , paragraph: e.target.value})} />
                                                        //         </div>
                                                        //     </div>
                                                        // </Card.Body>
                                                            }
                                                            </Modal.Body>
                                                            <div className='d-flex flex-row col-md-8 m-2 p-2'>
                                                            <Button variant='primary' className='mx-2' onClick={howItWorksEdited ? handleEditWork : HandleAddWork }>{howItWorksEdited ? "Edit" : "Add"}</Button>
                                                            <Button variant="outline-danger" className='mx-2' onClick={handleCloseWorksModel}>
                                                                Close
                                                            </Button>
                                                            </div>
                                                        </Modal>
                                                            <div className="col-md-12">
                                                                <Accordion>
                                                                    {howItWorksList && howItWorksList.map((e, index) => (
                                                                            <Card key={index}>
                                                                                <Card.Header>
                                                                                        <div className="d-flex flex-row justify-content-between">
                                                                                        {e.heading}
                                                                                        <div>
                                                                                        <Button className='my-2 btn mx-1' variant="primary" onClick={()=>handleEditHowItWorks(e)}>Edit</Button>
                                                                                        {/* <Button className='my-2 btn mx-1' variant="outline-danger" onClick={() => handleDeleteHowItWorks(e.heading)}>Delete</Button> */}
                                                                                        </div>
                                                                                        </div>
                                                                                </Card.Header>
                                                                            </Card>
                                                                    ))}
                                                                </Accordion>
                                                            </div>
                                                        <div className="col-md-12">
                                                            <button className="btn btn-primary" disabled={isLoading} onClick={submitHowItWorksContent}>{isLoading ? 'Saving...' : 'Save'}</button>
                                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}  disabled={isLoading}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )}

                        </div>}

                </div>
              </div>
          </div>
        // </div>
  )
}
