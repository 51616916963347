import { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PageLoader from "../Components/Loader/PageLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Helpers from "../Config/Helpers";
import { IoIosArrowBack } from "react-icons/io";


const defaultUser = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    is_special: 1
}

const Starter = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [user, setUser] = useState(defaultUser);
    const [pageLoading, setPageLoading] = useState(false);
    const [plan, setPlan] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(true);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    const {plan_id} = useParams();
    
    const onChangePage = () => {
      setPage(!page);
    };
    const getPlan = () => {
        setPageLoading(true);
        axios.get(`${Helpers.apiUrl}plans/getSpecial`, Helpers.authHeaders).then(response => {
            setPlan(response.data.plan);
            setPageLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            navigate(-1);
            setPageLoading(false);
        })
    }

    const processPayment = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // const { token } = await stripe.createToken(elements.getElement(CardElement));
        
    if (!stripe || !elements) {
        console.error("Stripe has not loaded");
        setIsLoading(false);
        return;
      }
  
      const cardElement = elements.getElement(CardElement);
  
      const { paymentMethod, error: paymentMethodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            email: user.email,
          },
        });
  
      if (paymentMethodError) {
        Helpers.toast('error', paymentMethodError.message)
        console.error(paymentMethodError);
        setIsLoading(false);
        return;
      }
  
        if(paymentMethod){
            let data = {
                email: user.email,
                name: user.name,
                password: user.password,
                plan_id: plan.id,
                payment_method: paymentMethod.id,
                is_special: 0
            }
            axios.post(`${Helpers.apiUrl}stripe/process-starter`, data, Helpers.authHeaders).then(async response => {

                // Confirmations can be added sample(in the most bottom) but will cause error

                Helpers.toast("success", response.data.message);
                Helpers.setItem("user", response.data.user, true);
                Helpers.setItem("token", response.data.token);
                const loginTimestamp = new Date().getTime();
                Helpers.setItem("loginTimestamp", loginTimestamp);
                setTimeout(() => {
                    window.location.href = '/user/dashboard';
                }, 1000);
            }).catch(error => {
                if(error.response){
                    Helpers.toast("error", error.response.data.message);
                }else{
                    Helpers.toast("error", "Unexpected error occured");
                }
                setIsLoading(false);
            });
        }else{
            Helpers.toast("error", "Unexpected error occured");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getPlan();
    }, []);


    return (
        <div class="nk-content p0">
            <div class="container-xl p0">
                <div class="nk-content-inner">
                    {pageLoading ? 
                    
                    <main class="nk-pages">
                    <section class="section section-bottom-0 pb-5 has-mask">
                        <div class="nk-shape bg-shape-blur-k end-50 top-0"></div>
                        <div class="nk-shape bg-shape-blur-l start-50 top-75"></div>
                        <PageLoader /> 
                     </section>
                     </main>
                     : 
                    <>
                    {page && (
                        <main class="nk-pages">
                        <section class="section section-bottom-0 pb-5 has-mask">
                            <div class="nk-shape bg-shape-blur-k end-50 top-0"></div>
                            <div class="nk-shape bg-shape-blur-l start-50 top-75"></div>
                            <div class="container">
                                <div class="section-head">
                                    <div class="row justify-content-center text-center">
                                        <div class="col-lg-11 col-xl-10 col-xxl-9">
                                            <h6 class="overline-title text-primary">Join eComEmail.AI!</h6>
                                            <h2 class="title">Register Now</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="section-content mb-100">
                                    <div class="row g-gs justify-content-center">
                                        <div class="col-md-7 col-lg-6 col-xl-5">
                                            <div class="card border-0 shadow-sm rounded-4">
                                                <div class="card-body">
                                                    <form onSubmit={onChangePage}>
                                                        <div class="row g-1">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="form-label" for="emailorusername">Name</label>
                                                                    <div class="form-control-wrap">
                                                                        <input type="text" class="form-control form-control-lg" value={user.name} onChange={e => setUser({...user, name: e.target.value})} placeholder="Enter Full Name" />
                                                                        <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="form-label" for="emailorusername">Email</label>
                                                                    <div class="form-control-wrap">
                                                                        <input type="email" class="form-control form-control-lg" value={user.email} onChange={e => setUser({...user, email: e.target.value})} placeholder="Enter Email" />
                                                                        <small className="text-danger">{ errors.email ? errors.email[0] : '' }</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="form-label" for="toggle-password">Password</label>
                                                                    <div class="form-control-wrap">
                                                                        <input type="password" value={user.password} onChange={e => setUser({...user, password: e.target.value})} class="form-control form-control-lg" placeholder="Enter Password" />
                                                                        <small className="text-danger">{ errors.password ? errors.password[0] : '' }</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="form-label" for="toggle-password">Confirm Password</label>
                                                                    <div class="form-control-wrap">
                                                                        <input type="password" value={user.password_confirmation} onChange={e => setUser({...user, password_confirmation: e.target.value})} class="form-control form-control-lg" placeholder="Confirm Password"  />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="d-flex flex-wrap justify-content-between has-gap g-3 text-right">
                                                                    {/* <div class="form-group">
                                                                        <div class="form-check form-check-sm">
                                                                            <input class="form-check-input" type="checkbox" value="" id="rememberMe" /><label class="form-check-label" for="rememberMe"> I Agree to eComEmail.AI <a href="#!">Privacy Policy</a>, <a href="#!">Terms & Conditions</a> </label>
                                                                        </div>
                                                                    </div> */}
                                                                    <a href="/" class="small">Forgot Password?</a>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group"><button class="btn btn-primary btn-block" type="submit" disabled={isLoading} onClick={onChangePage}>{isLoading ? 'Creating account...' : 'Create My Free Account'}</button></div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <p class="text-center mt-4">Already have an account? <Link to="/login">Login</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                    )}

                    {!page && (
                        
                <main class="nk-pages">
                <section class="section section-bottom-0 pb-5 has-mask">
                    <div class="nk-shape bg-shape-blur-k end-50 top-0"></div>
                    <div class="nk-shape bg-shape-blur-l start-50 top-75"></div>
                    <div class="nk-content-body">
                    <IoIosArrowBack 
                    className="bg-primary mx-5 mt-5 p-1" 
                    fill="white" 
                    style={{
                        fontSize: "2rem", 
                        borderRadius: "50%",
                        cursor: "pointer"
                    }}
                    onClick={onChangePage}
                    />
                        <div class="d-flex flex-wrap flex-grow-1">
                            <div class="bg-lighter w-100 w-lg-50 d-flex align-items-center justify-content-center justify-content-lg-end p-4 p-sm-5">
                                <div class="wide-xs w-100">
                                    <div class="d-flex">
                                        <Link class="pe-2 d-flex align-items-center" to="/user/pricing-plans"><em class="icon ni ni-arrow-left text-light"></em></Link>
                                        <a href="#!" class="logo-link">
                                            <div class="logo-wrap">
                                                <img class="logo-img logo-light" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                                <img class="logo-img logo-dark" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                                <img class="logo-img logo-icon" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                            </div>
                                        </a>
                                    </div>
                                    <div class="pt-4">
                                        <div class="fs-4 fw-normal">Subscribe to { plan.plan_name } ({ 'Monthly' })</div>
                                        <h6 class="display-5 fw-semibold">${ parseFloat(plan.monthly_price).toFixed(2) }</h6>
                                        <div class="fs-5 fw-normal mt-2">{ plan.description }</div>
                                    </div>
                                    <ul class="pricing-features">
                                        {plan.plan_features && plan.plan_features.map(feature => <li><em class="icon text-primary ni ni-check-circle m-2"></em><span>{ feature.feature }</span></li>)}
                                    </ul>
                                    <div class="pt-lg-5"></div>
                                </div>
                            </div>
                            <div class="bg-white w-100 w-lg-50 d-flex align-items-center justify-content-center justify-content-lg-start p-4 p-sm-5">
                                <div class="wide-xs w-100">
                                    <h2 class="mb-3 fw-normal">Pay with card</h2>
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-label" for="email">Email</label>
                                                <div class="form-control-wrap"><input class="form-control" value={user.email} placeholder="Enter email address" /></div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div className="form-group">
                                                <label class="form-label" for="email">Card Details</label>
                                                <div className="form-group-wrapper card-element">
                                                    <CardElement />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="form-label" for="name">Name on card</label>
                                                <div class="form-control-wrap"><input class="form-control" type="text" value={user.name} placeholder="Full Name" /></div>
                                            </div>
                                        </div>
                                        {/* <div class="col-12">
                                            <div class="form-check flex-nowrap p-2 border border-light rounded my-1">
                                                <input class="form-check-input mt-0 flex-shrink-0" type="checkbox" value="" id="savecard" />
                                                <label class="form-check-label" for="savecard">
                                                    <h6 class="mb-1">Securely save my information for 1-click checkout</h6>
                                                    <p class="fs-12px lh-sm">Pay faster on Genious.AI and everywhere Link is accepted.</p>
                                                </label>
                                            </div>
                                        </div> */}
                                        <div class="col-12">
                                            <div class="form-group"><button onClick={processPayment} disabled={isLoading} class="btn btn-primary w-100">{isLoading ? 'Processing...' : 'Subscribe'}</button></div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-note">
                                                By confirming your subscription, you allow eComEmail.AI to charge your card for this payment and future payments in accordance with their terms. You can always cancel your subscription.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                    </main>)}
                    </>}
                </div>
            </div>
        </div>
    );
}

export default Starter;


    //   const { error: confirmError } = await stripe.confirmCardPayment(
    //     response.data.clientSecret,
    //     {
    //       payment_method: paymentMethod.id,
    //     }
    //   );
    
    //   if (confirmError) {
    //     Helpers.toast('error', confirmError);
    //     console.error(confirmError);
    //     return;
    //   }