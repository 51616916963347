import React, { useState, useEffect } from "react";
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import TextInput from "../../../Components/Input";
import PageLoader from "../../../Components/Loader/PageLoader";
import useTitle from '../../../Hooks/useTitle';
export default function Action(){
    useTitle("Actions")
    
    let defaultAction = {
        name: "",
        action: ""
    } 
    const [actions, setActions] = useState([]);
    const [addActionModel, setAddActionModel] = useState(false);
    const [editableAction, setEditableAction] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [action, setAction] = useState(defaultAction);
    const [editActionModel, setEditActionModel] = useState(false);
    const [errors, setErrors] = useState({});
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(()=>{
        getUsers();
    },[])

    const getUsers = () => {
        setPageLoading(true)
        axios.get(`${Helpers.apiUrl}action-list/get`, Helpers.authHeaders).then(response => {
                setActions(response.data.action);
                setPageLoading(false)
        });
    }

    const addAction = (edit) => {
        setIsLoading(true);
        setErrors({});
        const url = edit ? `${Helpers.apiUrl}action-list/update/${action.id}` : `${Helpers.apiUrl}action-list/add`;
        axios.post(url, action, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setAction(defaultAction);
            setActions(response.data.actions);
            setIsLoading(false);
            setEditActionModel(false);
            setAddActionModel(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        });
    }

    const deleteAction = (id) => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}action-list/delete/${id}`, id, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setAction(defaultAction);
            setActions(response.data.actions);
            setIsLoading(false);
            setEditActionModel(false);
            setAddActionModel(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        });
    }


    const ActionsModelView = () => {
        setAddActionModel(!addActionModel)
    }

    const editAction = (item) => {
        setAction(item);
        setEditActionModel(true);
    }

    const EditModelView = () => {
        setAction(null);
        setEditActionModel(false);
    }
 
    return(
        <div class="nk-content">
            <div class="container-xl">
                {pageLoading ? <PageLoader/> :(
                <div class="nk-content-inner">
                    <div class="nk-content-body">
                        <div class="nk-block-head nk-page-head">
                            <div class="nk-block-head-between">
                                <div class="nk-block-head-content">
                                    <h2 class="display-6">Actions</h2>
                                    <p>Manage Actions List</p>
                                    </div>
                                    {/* <div>
                                        <button class="btn btn-primary" onClick={ActionsModelView}>
                                            <em class="icon ni ni-plus"></em> 
                                            Add Action</button>
                                    </div> */}
                            </div>
                        </div>

                        {(!addActionModel && !editActionModel) && (
                        <div class="nk-block">
                            <div class="mt-xl-5">
                                <div class="row g-0">

                                {/* Map Function */}
                                {actions.map((item) => (
                                    <div class="col-xl-4" key={item.id}>
                                        <div class="card pricing bg-white rounded-start">
                                            <div class="pricing-content">
                                                <div class="w-sm-70 w-md-50 w-xl-100 text-center text-xl-start mx-auto">
                                                    <h2 class="mb-3">
                                                    {item.action}
                                                    </h2>
                                                    <ul class="pricing-features">
                                                        <li>
                                                            Name: 
                                                            <span>{item.name}</span>
                                                        </li>

                                                        {/* <li>
                                                            Action: 
                                                            <span>{item.action}</span>
                                                        </li> */}
                                                    </ul>
                                                    <div class="mt-3">
                                                        <button class="btn btn-info w-100" onClick={() => editAction(item)}>
                                                            Edit Action
                                                        </button>
                                                    </div>
                                                    <div class="mt-3">
                                                        {/* <button class="btn btn-outline-danger w-100" onClick={()=>deleteAction(item.id)}>
                                                            Delete Action
                                                        </button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                        )}
                    </div>

                    {addActionModel && <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Add New Action</h3></div>
                            </div>
                            <div class="card shadown-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <TextInput label={"Name"} cols={12} name='name' onChange={(e)=>{setAction({...action, name: e.target.value})}} />
                                        <TextInput label={"Action"} cols={12} name='action' onChange={(e)=>{setAction({...action, action: e.target.value})}}/>
                                        <div className="col-md-12">
                                            <button className="btn btn-primary" disabled={isLoading} onClick={()=>addAction(false)}>{isLoading ? 'Saving...' : 'Save Action'}</button>
                                            <button className="btn btn-outline-danger ml10" onClick={ActionsModelView} disabled={isLoading}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {editActionModel && (
                            <>
                            <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Edit Action</h3></div>
                            </div>
                            <div class="card shadown-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <TextInput label={"Name"} cols={12} value={action.name} onChange={(e)=> {setAction({...action, name: e.target.value})}} />
                                        {/* <TextInput label={"Action"} cols={12} value={action.action} onChange={(e)=> {setAction({...action, action: e.target.value})}} /> */}
                                        <div className="col-md-12">
                                            <button className="btn btn-primary" disabled={isLoading} onClick={()=>addAction(true)} >{isLoading ? 'Saving...' : 'Save Action Name'}</button>
                                            <button className="btn btn-outline-danger ml10" onClick={EditModelView} disabled={isLoading}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </>
                        )}
                </div>
                )}
            </div>
        </div>
    )
}