import React from 'react'
import { Link } from 'react-router-dom'

export default function Welcome() {
  return (
    <main class="nk-pages">
    <section class="section section-bottom-0 pb-5 has-mask">
        <div class="nk-shape bg-shape-blur-k end-50 top-0"></div>
        <div class="nk-shape bg-shape-blur-l start-50 top-75"></div>
        <div class="container">
            <div class="section-content mb-100">
                <div class="row g-gs justify-content-center">
                    <div class="col-md-7 col-lg-6 col-xl-5">
                        <div class="card border-0 shadow-sm rounded-4">
                            <div class="card-body">
                                    <div class="row g-1">
                                        <div class="col-12">
                                            <div class="form-group">
                                                    <h2 class="title text-primary text-center">Thanks</h2>
                                                <div class="form-control-wrap">                           
                                                    <h6 class="overline-title">You will receive a mail Shortly regarding your Free Trial Activation</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <p class="text-center mt-4">Already have an account? <Link to="/login">Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
  )
}
