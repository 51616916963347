import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import PageLoader from '../../../Components/Loader/PageLoader';

export default function Settings() {
    const [apiKey, setApiKey] = useState('')
    const [gptModel, setGptModel] = useState('')
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});

    const getKey = () =>{
        setLoading(true)
        axios.get(`${Helpers.apiUrl}setting/get`, Helpers.authHeaders).then((response)=>{
            setApiKey(response.data.data.api);
            setGptModel(response.data.data.gptModel);
            setLoading(false);
            Helpers.toast('success', response.data.message);
        }).catch((error)=>{
            Helpers.toast('error', error);
            setErrors({error});
            setLoading(false);
        })
    }

    const saveKey = () => {
        setLoading(true)
        if(!apiKey || !gptModel){
            Helpers.toast("error","ChatGPT API Key and Model are required");
            return;
        }
        const formData = new FormData();
        formData.append('api', apiKey);
        formData.append('gptModel', gptModel);
        axios.post(`${Helpers.apiUrl}setting/save`, formData, Helpers.authHeaders).then((response)=>{
            const data = response.data.data;
            if (data['OPEN AI API']) {
                setApiKey(data['OPEN AI API'].value);
            }
            if (data['gptModel']) {
                setGptModel(data['gptModel'].value);
            }
            setLoading(false);
            Helpers.toast('success', response.data.message);
        }).catch((error)=>{
            Helpers.toast('error', error);
            setErrors({error});
            setLoading(false);
        })
    }

    useEffect(()=>{
        getKey();
    },[])

  return (
    <div class="nk-content container-xl bg-white">
    <div class="container-xl">


    <div class="nk-content-inner">
            <div class="nk-content-body">

                {(loading) ? <PageLoader></PageLoader> : (<div class="nk-block-head nk-page-head">
                    <div class="">
                        <div class="nk-block-head-content">
                            <h2 class="display-6">Settings</h2>
                            <p>Manage the Settings of the App</p>
                        </div>
                        <div className="d-flex justify-content-end m-2">

                        <div class="nk-block col-md-12">

                          <div class="card shadown-none">
                            <div class="card-body">
                                <div class="row g-gs">
                                    <div class="form-group">
                                        <label class="form-label">ChatGPT API Key:</label>
                                        <div class="form-control-wrap">
                                            <input type="text" value={apiKey} onChange={e => setApiKey(e.target.value)} class="form-control" placeholder="Enter API for ChatGPT" />
                                            <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small>
                                        </div>
                                        
                                        <label class="form-label">ChatGPT Model:</label>
                                        <div class="form-control-wrap">
                                            <select class="form-control" id="model" name="gptModel" value={gptModel} onChange={e=>setGptModel(e.target.value)}>
                                                <option value="" >
                                                    Select ChatGPT Model
                                                </option>
                                                <option value="gpt-4o" >
                                                    gpt-4o
                                                </option>
                                                <option value="gpt-4-1106-preview" >
                                                    gpt-4-1106-preview
                                                </option>
                                                <option value="gpt-4-0125-preview" f>
                                                    gpt-4-0125-preview
                                                </option>
                                                <option value="gpt-4-turbo-preview">
                                                    gpt-4-turbo-preview
                                                </option>
                                                <option value="gpt-4" >
                                                    gpt-4</option>
                                                <option value="gpt-4-0613" >
                                                    gpt-4-0613</option>
                                                <option value="gpt-4-32k" >
                                                    gpt-4-32k</option>
                                                <option value="gpt-4-32k-0613">
                                                    gpt-4-32k-0613</option>
                                                <option value="gpt-3.5-turbo-0125">
                                                    gpt-3.5-turbo-0125</option>
                                                <option value="gpt-3.5-turbo">
                                                    gpt-3.5-turbo</option>

                                            </select>
                                            <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <button className='btn btn-primary m-2' onClick={saveKey}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                </div>)}

            </div>
        </div>


    </div>
    </div>
  )
}