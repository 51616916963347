const TextWrapper = ({ text }) => {
    const limitWords = (text, limit) => {
      const words = text.split(' ');
      return words.length > limit ? words.slice(0, limit).join(' ') + '...' : text;
    };
  
    return (
      <div>
        {limitWords(text, 15)}
      </div>
    );
  };
  
  export default TextWrapper;