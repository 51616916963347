// const APIResponse = ({ response, writing = false, onlyFirstPara = false }) => {
//     // Format the response text
//     let formattedResponse = response.replace(/```/g, '');
    
//     // formattedResponse = response.replace(/\\n/g, '\n');
//     formattedResponse = response.replace(/\\n/g, '\n').replace(/\\"/g, '\"');

//     // Split the formatted response into separate paragraphs
//     let paragraphs = formattedResponse.split('\n');

//     return (
//         <div>
//             {/* <p key={index}>{ paragraph }dangerouslySetInnerHTML={{ __html: paragraph }}</p> */}
//             {(response && !onlyFirstPara) && paragraphs.map((paragraph, index) => (
//                 <p key={index}>{ paragraph }
//                     {(paragraphs.length - 1 === index && writing) && <button style={{ height: 10, width: 10, border: '1px solid #21A1F6',borderRadius: 5, background: '#21A1F6' }}></button>}
//                 </p>
//             ))}
//             {onlyFirstPara && <p>{ paragraphs[0] }</p>}
//         </div>
//     );
// }

// export default APIResponse;

const APIResponse = ({ response, writing = false, onlyFirstPara = false }) => {
    if (response === null || response === undefined) {
        return <div>No response available.</div>;
    }
    // Function to format bold text
    const formatText = text => {
        let formattedText = text;
        // Format bold text
        formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        // Format italic text
        formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');
    
        return formattedText;
    };

    // Format the response text
    let formattedResponse = response.replace(/\\n/g, '\n').replace(/\\"/g, '\"');
    
    // Split the formatted response into separate paragraphs and format bold text
    let paragraphs = formattedResponse.split('\n').map(paragraph => formatText(paragraph));

    return (
        <div>
            {(response && !onlyFirstPara) && paragraphs.map((paragraph, index) => (
                <div key={index}>
                    <p dangerouslySetInnerHTML={{ __html: paragraph }} />
                    {(paragraphs.length - 1 === index && writing) && 
                        <button style={{ height: 10, width: 10, border: '1px solid #21A1F6', borderRadius: 5, background: '#21A1F6' }}></button>
                    }
                </div>
            ))}
            {onlyFirstPara && <p dangerouslySetInnerHTML={{ __html: paragraphs[0] }} />}
        </div>
    );
}

export default APIResponse;
