import { useEffect, useState } from "react";
import useTitle from "../Hooks/useTitle";
import axios from "axios";
import Helpers from "../Config/Helpers";

const UseCases = () => {
    useTitle("Use Cases");
    const [prompts, setPrompts] = useState([]);

    const getPrompts = () => {
        axios.get(`${Helpers.apiUrl}all-prompts`).then(response => {
            setPrompts(response.data.all);
        });
    }
    useEffect(() => {
        getPrompts();
    }, []);
    return (
        <main class="nk-pages">
            <div className="nk-mask bg-gradient-a"></div>
            <section class="section section-lg">
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-9 col-xl-8 col-xxl-7"><h2 class="title h1">What amazing content will you create with AI?</h2></div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs">
                            {prompts.map(prompt => {
                                return (
                                    <div class="col-md-6 col-xl-4">
                                        <div class="card rounded-4 border-0 shadow-sm h-100">
                                            <div class="card-body">
                                                <div class="feature">
                                                    <div class="feature-media">
                                                        <div class="media media-middle media-lg text-white bg-primary rounded-3">{ prompt.name.charAt(0) }</div>
                                                    </div>
                                                    <div class="feature-text">
                                                        <h4 class="title">{ prompt.name }</h4>
                                                        <p>{ prompt.description }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default UseCases;