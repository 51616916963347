import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import { IoMdClose } from "react-icons/io";

const AdminLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [showMobileNav, setShowMobileNav] = useState(false);
    const [isCompact, setIsCompact] = useState(false);

    const logout = (e) => {
        e.preventDefault();
        Helpers.toast("success", "Logged out successfully");
        localStorage.clear();
        navigate('/');
    }
    
    useEffect(() => {
        Helpers.toggleCSS();
        setShowMobileNav(false);
    }, [location.pathname]);
    
    
    return (
        <div class="nk-app-root" data-sidebar-collapse="lg">
            <div class="nk-main">
                {showMobileNav && <div onClick={() => setShowMobileNav(false)} className="sidebar-overlay"></div>}
                <div class={`nk-sidebar nk-sidebar-fixed ${isCompact && 'is-compact'} ${showMobileNav && 'sidebar-active' }`} id="sidebar">
                    <div class="nk-compact-toggle">
                        <button onClick={() => setIsCompact(!isCompact)} class="btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3">
                            <em class={`icon off ni ${ isCompact ? 'ni-chevron-right' : 'ni-chevron-left' }`}></em>
                        </button>
                    </div>
                    <IoMdClose 
                        className="mx-2 mt-2 fs-3 mobile-view-only" 
                        style={{display: showMobileNav ? 'block': "none",alignSelf: "end", right: '0%', position: "relative"}}
                        onClick={()=>setShowMobileNav(!showMobileNav)}
                        ></IoMdClose>
                    <div class="nk-sidebar-element nk-sidebar-head">
                        <div class="nk-sidebar-brand">
                            <a href="/" class="logo-link">
                                <div class="logo-wrap">
                                    <img class="logo-img logo-light" src="/logo-dashboard-white.png" alt="" />
                                    <img class="logo-img logo-dark" src="/logo-dashboard-white.png" alt="" />
                                    <img class="logo-img logo-icon compact-logo" src="/favicon-white.png" alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="nk-sidebar-element nk-sidebar-body">
                        <div class="nk-sidebar-content h-100" data-simplebar>
                            <div class="nk-sidebar-menu">
                                <ul class="nk-menu">
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/dashboard'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span><span class="nk-menu-text">Dashboard</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/users'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-users"></em></span><span class="nk-menu-text">Users</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/chat-history'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-clock"></em></span><span class="nk-menu-text">Chat History</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/starter-plan'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-user-fill"></em></span><span class="nk-menu-text">Starter</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/content'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-text2"></em></span><span class="nk-menu-text">Content</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/categories'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-tag"></em></span><span class="nk-menu-text">Categories</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/prompts'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-file"></em></span><span class="nk-menu-text">Prompts</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/instructions'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-info"></em></span><span class="nk-menu-text">Instructions</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/buttons'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-view-grid"></em></span><span class="nk-menu-text">Automation Buttons</span>
                                        </Link>
                                    </li>
                                    {/* <li class="nk-menu-item">
                                        <Link to={'/admin/stripe-products'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-sign-usdc"></em></span><span class="nk-menu-text">Stripe Products</span>
                                        </Link>
                                    </li> */}
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/free-plan'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-gift"></em></span><span class="nk-menu-text">Free Plan</span>
                                        </Link>
                                    </li>
                                    {/* Trial Plans */}
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/trial-plan'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-tags-fill"></em></span><span class="nk-menu-text">Trial Plans</span>
                                        </Link>
                                    </li>
                                    {/* Pricing Plans */}
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/pricing-plans'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-sign-usdc"></em></span><span class="nk-menu-text">Pricing Plans</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/action-list'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-list-thumb"></em></span><span class="nk-menu-text">Action List</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/lists-and-actions'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><i class="icon fa-light fa-list"></i></span><span class="nk-menu-text">Lists & Actions</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <Link to={'/admin/settings'} class="nk-menu-link">
                                            <span class="nk-menu-icon"><i class="icon fa-light fa-cog"></i></span><span class="nk-menu-text">Settings</span>
                                        </Link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <a href="#!" onClick={logout} class="nk-menu-link">
                                            <span class="nk-menu-icon"><em class="icon ni ni-signout"></em></span><span class="nk-menu-text">Sign Out</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="nk-sidebar-element nk-sidebar-footer">
                        <div class="nk-sidebar-footer-extended pt-3">
                            <div class="border border-primary rounded-3">
                                <a class="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3" href="#!">
                                    <div class="media-group">
                                        <div class="media media-sm media-middle media-circle text-bg-primary"><img src={Helpers.serverImage(Helpers.authUser.profile_pic)} alt="" /></div>
                                        <div class="media-text">
                                            <h6 class="fs-6 mb-0 color-white">{ Helpers.authUser.name }</h6>
                                        </div>
                                        <em class="icon ni ni-chevron-right ms-auto ps-1 color-white"></em>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nk-wrap">
                    <div class="nk-header nk-header-fixed">
                        <div class="container-fluid">
                            <div class="nk-header-wrap">
                                <div class="nk-header-logo ms-n1">
                                    <div class="nk-sidebar-toggle me-1">
                                        <button class="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none"><em class="icon ni ni-menu"> </em></button>
                                        <button onClick={() => setShowMobileNav(true)} class="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex"><em class="icon ni ni-menu"> </em></button>
                                    </div>
                                    <a href="index-2.html" class="logo-link">
                                        <div class="logo-wrap">
                                            <img class="logo-img logo-light" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                            <img class="logo-img logo-dark" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                            <img class="logo-img logo-icon" src="/logo-dashboard.png" srcset="/logo-dashboard.png 2x" alt="" />
                                        </div>
                                    </a>
                                </div>
                                <div class="nk-header-tools">
                                    <ul class="nk-quick-nav ms-2">
                                        <li class="dropdown d-inline-flex">
                                            <Link class="d-inline-flex" to={'/user/profile'}>
                                                <div class="media media-sm media-middle media-circle text-bg-primary"><img className="chat-avatar" src={Helpers.serverImage(Helpers.authUser.profile_pic)} alt="" /></div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Outlet />
                    <div class="nk-footer">
                        <div class="container-xl">
                            <div class="d-flex align-items-center flex-wrap justify-content-between mx-n3">
                                <div class="nk-footer-links px-3">
                                    <ul class="nav nav-sm">
                                        <li class="nav-item"><Link class="nav-link" to="/">Home</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/user/pricing-plans">Pricing</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/privacy-policy">Privacy Policy</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/cookie-policy">Cookie Policy</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/terms-and-conditions">Terms & Conditions</Link></li>
                                        <li class="nav-item"><Link class="nav-link" to="/disclaimer">Disclaimer</Link></li>
                                    </ul>
                                </div>
                                <div class="nk-footer-copyright fs-6 px-3">&copy; 2023 All Rights Reserved to <Link to="/">eComEmail.AI</Link>.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;