import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import TextInput from "../../../Components/Input";
import useTitle from "../../../Hooks/useTitle";
import Select from "react-select";
import PageLoader from "../../../Components/Loader/PageLoader";
import Wrapper from "../../../Components/Wrapper";

const AdminFreePlan = () => {
    useTitle("Free Trial");

    const defaultPlan = {
        plan_name:"",
        monthly_price:"",
        no_words:"",
        description:"",
        activated: 0
    };

    const defaultFeature = {
        feature:"",
        pricing_plan_id:"",
    }

    const defaultActivated = {
        id: "",
        name: ""
    }

    const [plan, setPlan] = useState(defaultPlan);
    const [plans, setPlans] = useState([]);
    const [features, setFeatures] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showEditPlan, setShowEditPlan] = useState(false);
    const [showFeatures, setShowFeatures] = useState(false);
    const [feature, setFeature] = useState(defaultFeature);
    const [viewPlan, setViewPlan] = useState(false);
    const [activePlan, setActivePlan] = useState('monthly');
    const [showAddPlan, setShowAddPlan] = useState(false);
    const [activatedOptions, setActivatedOptions] = useState([])
    const [selectedActivationFreeTrial, setSelectedActivationFreeTrial] = useState(null);

    const getPlans = () => {
        setPageLoading(true);
        axios.get(`${Helpers.apiUrl}free-plan/get`, Helpers.authHeaders).then(response => {
            // setPlans(response.data.plans);
            setPlans(response.data.plan);
            setPageLoading(false);
            handleSetActivatedOptions(response.data.plan);
        });
    }

    const handleSetActivatedOptions = (data) => {
        let options = [];
        for(let i = 0; i < data.length; i++){
            let option = {
                label: data[i].name,
                value: data[i].id,
            };
            options.push(option);
        }
        setActivatedOptions(options);
    } 

    const savePlan = (edit) => {
        setIsLoading(true);
        setErrors({});
        const url = edit ? `${Helpers.apiUrl}free-plan/update` : `${Helpers.apiUrl}free-plan/add`;
        axios.post(url, plan, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setPlan(defaultPlan);
            setPlans(response.data.plans);            
            // setFeatures([response.data.plans.no_words, response.data.plans.no_days]);
            setIsLoading(false);
            setShowEditPlan(false);
            setShowAddPlan(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
    });
    }

    const handleDelete = (id) => {
        setIsLoading(true)
        axios.post(`${Helpers.apiUrl}free-plan/delete/${id}`, {}, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setPlan(defaultPlan);
            setPlans(response.data.plans);            
            setIsLoading(false);
            setShowEditPlan(false);
            getPlans();
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
    });
    }


    const editPlan = (planToEdit) => {
        setPlan(planToEdit);
        setViewPlan(false);
        setShowEditPlan(true);
    }

    const handleAddCancel = () => {
        setPlan(defaultPlan);
        setShowAddPlan(false)
    }

    const handleCancel = () => {
        setPlan(defaultPlan);
        setShowEditPlan(false);
    }

    const showAllPlans = () => {
        setSelectedPlan(0);
        setPlan(defaultPlan);
        setViewPlan(false);
    }

    const handleShowAddPlan = () => {
        setViewPlan(false);
        setShowAddPlan(!showAddPlan);
    }

    const handleActivatePackage = (id) => {
        setIsLoading(true)
        axios.post(`${Helpers.apiUrl}free-plan/activated/${id}`, {}, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setPlan(defaultPlan);
            setPlans(response.data.plans);            
            setIsLoading(false);
            setShowEditPlan(false);
            getPlans();
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        });
    }
    
    useEffect(() => {
        getPlans();
    }, []);

    return (
        <div class="nk-content">
            <div class="container-xl">
                <div class="nk-content-inner">
                    {pageLoading ? <PageLoader /> : <div class="nk-content-body">
                        <div class="nk-block-head nk-page-head">
                            <div class="nk-block-head-between">
                                <div class="nk-block-head-content">
                                    <h2 class="display-6">Pricing Plans</h2>
                                    <p>Manage Pricing Plans</p>
                                </div>
                                <div className="d-flex flex-column justify-content-around align-items-center flex-md-row">
                                    <div className="d-flex flex-column justify-content-around align-items-center flex-md-row m-2">
                                    <Select 
                                    value={selectedActivationFreeTrial}
                                    options={activatedOptions} 
                                    placeholder={"Selected Activated Free Trial"}
                                    onChange={selectedActivationFreeTrial=> {setSelectedActivationFreeTrial(selectedActivationFreeTrial)}}
                                    ></Select>
                                    <button className="btn btn-primary m-2" onClick={()=>handleActivatePackage(selectedActivationFreeTrial.value)}><em class="icon ni ni-update"></em> Activate</button>
                                    </div>
                                    <button className="btn btn-primary" onClick={handleShowAddPlan}><em class="icon ni ni-plus"></em> Add New Plan</button>
                                    {viewPlan && <button className="btn btn-outline-danger ml10" onClick={showAllPlans}><em class="icon ni ni-arrow-left"></em> All Plans</button>}
                                </div>
                            </div>
                        </div>
                        {(!showEditPlan && !showAddPlan && !viewPlan) && <div class="nk-block">
                            <div class="mt-xl-5">
                                <div class="row g-0">
                                    {plans.map((pln, id) => {
                                        return (
                                            <div key={id} class="col-xl-4">
                                                <div className={pln.activated === 1 ? "card pricing bg-primary rounded-start" : "card pricing bg-white rounded-start"}>
                                                    <div class="pricing-content">
                                                        <div class="w-sm-70 w-md-50 w-xl-100 text-center text-xl-start mx-auto">
                                                            <h2 class="mb-3">{ pln.name }</h2>
                                                            <div class="pricing-price-wrap">
                                                                <div class={`pricing-price pricing-toggle-content monthly { activePlan === 'monthly' ? 'active' : '' }`}>
                                                                </div>
                                                            </div>
                                                            <ul class="pricing-features">
                                                                <li> No of Words <span>{ pln.no_words }</span></li>
                                                                <li> No of Days <span>{ pln.no_days }</span></li>
                                                                
                                                            </ul>
                                                            <div class="mb-2 mx-auto">
                                                                <button onClick={() => editPlan(pln)} class="btn btn-primary w-100 mb-1" disabled={isLoading}>Edit Plan</button>
                                                                {(plans.length > 1) && <button onClick={() => handleDelete(pln.id)} class="btn btn-outline-danger w-100 mb-1"  disabled={isLoading}>Delete</button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>}

                        {(showEditPlan) && <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Customize Free Trial</h3></div>
                            </div>
                            <div class="card shadown-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <TextInput error={errors.name} cols={12} label={"Name"} value={plan.name} onChange={e => setPlan({...plan, name: e.target.value})} />
                                        <TextInput error={errors.no_days} label={"No. of Days"} value={plan.no_days} onChange={e => setPlan({...plan, no_days: e.target.value})}/>
                                        <TextInput error={errors.no_words} label={"No. of Words"} value={plan.no_words} onChange={e => setPlan({...plan, no_words: e.target.value})} />
                                        <TextInput isTextArea={true} error={errors.description} label={"Description"} cols={12} value={plan.description} onChange={e => setPlan({...plan, description: e.target.value})} />
                                        <div class="form-check mx-3">
                                            <input 
                                                type="checkbox"
                                                error={errors.description}
                                                checked={plan.activated === 1} 
                                                className="form-check-input"
                                                id="exampleCheck1"
                                                onChange={(e) => {
                                                  const newValue = e.target.checked ? 1 : 0; 
                                                  setPlan({ ...plan, activated: newValue });
                                                }}
                                            />
                                            <label class="form-check-label" for="exampleCheck1">Also Activate this Package</label>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-primary" disabled={isLoading} onClick={()=>savePlan(true)}>{isLoading ? 'Saving...' : 'Save Free Trial'}</button>
                                            <button className="btn btn-outline-danger ml10" onClick={handleCancel}  disabled={isLoading}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {(showAddPlan) && <div class="nk-block">
                            <div class="nk-block-head nk-block-head-sm">
                                <div class="nk-block-head-content"><h3 class="nk-block-title">Add Free Trial</h3></div>
                            </div>
                            <div class="card shadown-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <TextInput error={errors.name}cols={12} label={"Name"} value={plan.name} onChange={e => setPlan({...plan, name: e.target.value})} />
                                        <TextInput error={errors.no_days} label={"No. of Days"} value={plan.no_days} onChange={e => setPlan({...plan, no_days: e.target.value})}/>
                                        <TextInput error={errors.no_words} label={"No. of Words"} value={plan.no_words} onChange={e => setPlan({...plan, no_words: e.target.value})} />
                                        <TextInput isTextArea={true} error={errors.description} label={"Description"} cols={12} value={plan.description} onChange={e => setPlan({...plan, description: e.target.value})} />
                                        <div class="form-check mx-3">
                                            <input 
                                                type="checkbox"
                                                error={errors.description}
                                                checked={plan.activated === 1} 
                                                className="form-check-input"
                                                id="exampleCheck1"
                                                onChange={(e) => {
                                                  const newValue = e.target.checked ? 1 : 0; 
                                                  setPlan({ ...plan, activated: newValue });
                                                }}
                                            />
                                            <label class="form-check-label" for="exampleCheck1">Also Activate this Package</label>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-primary" disabled={isLoading} onClick={()=>savePlan(false)}>{isLoading ? 'Saving...' : 'Save'}</button>
                                            <button className="btn btn-outline-danger ml10"  disabled={isLoading} onClick={handleAddCancel}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default AdminFreePlan;