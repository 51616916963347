import React from 'react'

export default function ImageInput({ label, value, onChange, error = null, cols = 6, placeholder = "", isSmall = false, isTextArea = false, rows=5,url, imgName, imageCols, uploaded }) {
  console.log(imgName);
  return (
    <div className={`col-md-${cols}`}>
        <div class="form-group">
            {label && <label class="form-label">{ label }</label>}
            <br></br>
            {uploaded
            ?  <img src='/tick.png' width={'50px'}></img>
            : <img className={`col-md-${imageCols ? imageCols : cols} p-5`} style={{borderRadius: "30px", backgroundColor: "white"}}   src={`${url}${imgName}`}></img>}
            <div class="form-control-wrap">
                {!isTextArea && <input className={`form-control ${ isSmall ? 'form-control-sm' : '' }`} type="file" name="image" accept="image/*" value={value} placeholder={placeholder ? placeholder : `Upload ${label}`} onChange={onChange} />}
                {isTextArea && <textarea className="form-control" value={value} rows={rows} placeholder={placeholder ? placeholder : `Enter ${ label }`} onChange={onChange}></textarea>}
                <small className="text-danger">{ error ? error[0] : '' }</small>
            </div>
        </div>
    </div>
  )
}
