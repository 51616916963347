import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'

class Helpers{
    static localhost = '127.0.0.1:8000';
    static server = 'api.ecomemail.ai';
    static testEnv = 't-api.ecomemail.ai';
    static basePath = `//${this.server}`;
    static apiUrl = `${this.basePath}/api/`;
    static imgUrl = `${this.basePath}/uploads/`
    static activatedHostApiUrl = `https://ecomemailai.api-us1.com`;
    static activatedHostApiKey = `e7a632ef82584010d9baf25e97d705a80d1b0ac396ddb3c946ae64608368933f75242567`;
    static TERMLY_API = 'eyJhbGciOiJIUzI1NiJ9.IjhnVkZsbXlZcC9OVW1TQkhzNDNDNWJNU3dkTEIyYk16dkJFdlpxRnZnTFFENUk5Nkp1T3lLTlRYdThEWnhoekl6M095dmRCRlpDVXBINm45aUtBeGo0cVo5bjR0dEtMV25jb3lpc3E0dUlaQTN6ZmFWcFdhZDZ4c003NG9SVkRhUjUvZEI0RGhiN1ozaXpOS0d3NTYzZz09LS1ieWNObHNnSjBTZ1luMFUzLS1MdGNNMXJHQXp2MTcyYlpFc3ZXTzFnPT0i.rMWs3O5whnl9W7Qlqh6yFXkzR6qWGfauqRpARCaPHgE';

    static authUser = JSON.parse(localStorage.getItem('user')) ?? {};

    static serverImage = (name) => {
      return `${this.basePath}/uploads/${name}`;
    }

    static authHeaders = {
        headers: {
            "Content-Type": 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('token')}`
        }
    }
    
    static authFileHeaders = {
        headers: {
            "Content-Type": 'multipart/form-data',
            "Authorization" : `Bearer ${localStorage.getItem('token')}`
        }
    }

    static getItem = (data, isJson = false) => {
      if(isJson){
        return JSON.parse(localStorage.getItem(data));
      }else{
        return localStorage.getItem(data);
      }
    }

    static setItem = (key, data, isJson = false) => {
      if(isJson){
        localStorage.setItem(key, JSON.stringify(data));
      }else{
        localStorage.setItem(key, data);
      }
    }

    static toast = (type, message) => {
        const notyf = new Notyf();
        notyf.open({
            message: message,
            type:type,
            position:{x:'right', y:'top'},
            ripple:true,
            dismissible:true,
            duration:2000,
        });
    }

    static encryptNumber = (number) => {
      let key =992342443;
      return number ^ key; // Simple XOR operation
  }
  
  // Decryption function for integers
  static decryptNumber = (encryptedNumber) => {
      let key =992342443;
      return encryptedNumber ^ key; // Simple XOR operation
  }

    static toggleCSS() {
      const path = window.location.pathname;
  
      // Assuming you have class names 'main-theme' and 'dashboard-theme' for your CSS links
      const mainCSS = document.getElementsByClassName('main-theme');
      const dashboardCSS = document.getElementsByClassName('dashboard-theme');
  
      if (path.includes('/user') || path.includes('/admin')) {
          // Disable all main theme stylesheets
          for (let i = 0; i < mainCSS.length; i++) {
              mainCSS[i].setAttribute('disabled', 'true');
          }
          // Enable all dashboard theme stylesheets
          for (let i = 0; i < dashboardCSS.length; i++) {
              dashboardCSS[i].removeAttribute('disabled');
          }
      } else {
          // Enable all main theme stylesheets
          for (let i = 0; i < mainCSS.length; i++) {
              mainCSS[i].removeAttribute('disabled');
          }
          // Disable all dashboard theme stylesheets
          for (let i = 0; i < dashboardCSS.length; i++) {
              dashboardCSS[i].setAttribute('disabled', 'true');
          }
      }
    }  

    static encryptObject = (obj) => {
        const str = JSON.stringify(obj);
        const encrypted = btoa(str);
        return encrypted;
    }

    static decryptObject = (str) => {
        const decrypted = atob(str);
        const obj = JSON.parse(decrypted);
        return obj;
    }

    static encryptString = (str) => {
        const encrypted = btoa(str);
        return encrypted;
    }

    static decryptString = (str) => {
      try {
        const decrypted = atob(str);
        return decrypted;
      } catch (error) {
        return "";
      }
    }

    static paginate = data => {
      let pageSize = 10;
      let paginated = [];
      let startIndex = 0;
      let totalPages = Math.ceil(data.length / pageSize);
      for(let i = 0; i < totalPages; i++){
        let lastIndex = pageSize + startIndex;
        let pageData = data.slice(startIndex, lastIndex);
        paginated.push(pageData);
        startIndex += pageSize;
      }
      return paginated;
    }

    static getContentValue = (dataString) => {
      try{
        let data = JSON.parse(dataString);
        if(data.choices && data.choices.length > 0){
          return data.choices[0].delta.content;
        }else{
          return "";
        }
      }catch(error){
        return "";
      }
    }

    static countWords = (str) => {
        if(str){
            let words = str.split(' ');
            return words.length;
        }else{
            return 0;
        }
    }
}

export default Helpers;