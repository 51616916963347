import { useEffect, useState } from "react";
import useTitle from "../Hooks/useTitle";
import axios from "axios";
import Helpers from "../Config/Helpers";
import { Link } from "react-router-dom";

const Home = () => {
    useTitle("Home");

    const [prompts, setPrompts] = useState([]);
    const [features, setFeatures] = useState([]);
    const [optimizationContent, setOptimizationContent] = useState([]);
    const [strategies, setStratergies] = useState([]);
    const [strategiesList, setStratergiesList] = useState([]);
    const [howItWorks, setHowItWorks] = useState();
    const [howItWorksList, setHowItWorksList] = useState([]);
    const [callAPI, setCallAPI] = useState(true);
    const [loading, setLoading] = useState(true);

    const getFeatures = () => {
    try{

        axios.get(`${Helpers.apiUrl}features/all`).then(response => {
            setFeatures(response.data.content);
        })
    }catch(error){
        Helpers.toast("error","An Error Occured");
        console.log(error)
    }
    }

    const getOptimizationContent = () => {
    try{
        axios.get(`${Helpers.apiUrl}optimisation/all`).then(response => {
            setOptimizationContent(response.data.content);
        })
    }catch(error){
        Helpers.toast("error","An Error Occured");
        console.log(error)
    }
    }

    const marketingStratergies = () => {
        try{
            axios.get(`${Helpers.apiUrl}strategies/all`).then(response => {
                setStratergies(response.data.content);
                setStratergiesList(JSON.parse(response.data.content.marketing_prompts).features);
            })
        }catch(error){
            Helpers.toast("error","An Error Occured");
            console.log(error)
        }
    }
    const WorksContent = () => {
        try{
            if(callAPI){
                axios.get(`${Helpers.apiUrl}howitworks/all`).then(response => {
                    setHowItWorks(response.data.content);
                    console.log("Working API call");
                    setHowItWorksList(JSON.parse(response.data.content.working_stratergies).working);
                })
            }
        }catch(error){
            Helpers.toast("error","An Error Occured");
            console.log(error)
        }finally{
            generateRandomSVG();
        }
    }

    const getPrompts = () => {
        axios.get(`${Helpers.apiUrl}all-prompts`).then(response => {
            setPrompts(response.data.prompts);
        });
    }

    const generateRandomSVG = () => {
        const svgArray = [
            <svg key={Math.random()} class="position-absolute top-0 start-0 mt-n5 ms-n3 me-6 z-index-n1" viewBox="0 0 413 367" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M297.773 365.385C240.904 379.987 171.136 291.086 138.891 279.276C75.5722 256.299 -32.89 263.6 9.61542 210.775C29.2559 186.295 70.2956 153.011 85.539 133.9C116.612 95.0327 55.6386 25.4583 110.749 5.05841C165.566 -15.1267 212.176 30.612 266.114 45.6435C301.584 55.736 339.692 50.7971 376.042 55.9508C400.959 59.6013 422.944 80.0012 408.287 99.9716C318.293 222.8 394.216 340.476 297.773 365.385Z"
                fill="#B4EBFC"
            />
        </svg>,
        <svg
        key={Math.random()}
        class="position-absolute top-0 start-0 mt-n5 mt-lg-n6 mt-xl-n7 ms-2 ms-sm-3 ms-md-0 ms-lg-1 me-5 me-sm-6 me-md-2 me-lg-0 me-xl-2 z-index-n1"
        viewBox="0 0 410 408"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M260.99 3.04631L55.1726 57.9406C15.7332 68.4596 -7.67183 108.811 2.89591 148.069L58.0447 352.937C68.6124 392.195 109.151 415.492 148.59 404.973L354.408 350.078C393.848 339.559 417.253 299.208 406.685 259.95L351.536 55.0818C340.968 15.8244 300.43 -7.47268 260.99 3.04631Z"
            fill="#B9B3FF"
        />
        </svg>,
        <svg
        key={Math.random()} 
        class="position-absolute top-0 start-0 mt-n5 ms-n5 z-index-n1" 
        viewBox="0 0 452 373" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M448.494 259.068C420.999 316.242 367.226 292.754 321.726 287.81C232.429 277.92 171.844 428.427 78.1669 350.546C-25.4856 264.322 -26.2156 107.634 76.9503 47.6789C365.766 -120.753 473.555 207.148 448.494 259.068Z"
            fill="#B9F7F5"
        />
        </svg>,
        ];
    
        const updatedList = howItWorksList.map(item => ({
          ...item,
          svg: svgArray[Math.floor(Math.random() * svgArray.length)],
        }));
        setCallAPI(false);
        console.log("Svg Set call");
        setHowItWorksList(updatedList);
      };

      useEffect(()=>{
        generateRandomSVG();
      }, [howItWorks])

      useEffect(() => {
        setLoading(true);
        Promise.all([
          getFeatures(),
          getPrompts(),
          getOptimizationContent(),
          marketingStratergies(),
          WorksContent(),
        ]).finally(() => setLoading(false));
      }, []);

      useEffect(() => {
        // Add or remove the class based on the loading state
        if (loading) {
          document.body.classList.add('hideOverflow');
        } else {
          document.body.classList.remove('hideOverflow');
        }
      }, [loading]);    

    return (
        <main class="nk-pages" style={{ overflowY: loading ? 'hidden' : 'visible' }}>
            <section class="section section-0 has-shape bg-primary mt-100">
                <div class="container">
                    <div class="section-content pb-75">
                        <div class="row text-center g-gs justify-content-center">
                            <div class="col-md-6 col-xl-4">
                                <div class="pt-6 h-100">
                                    <div class="card h-100 rounded-4 shadow-sm stat-card">
                                        <div class="card-body">
                                            <div class="feature">
                                                <div class="feature-media mt-n8 mb-3">
                                                    <div class="media media-3xl media-middle media-border text-bg-primary-soft-outline rounded-4">
                                                        <img src={`${Helpers.imgUrl}${features.icon_1}`} className="w50" alt="" />
                                                    </div>
                                                </div>
                                                <div class="feature-text">
                                                    <h4 class="title fz-50 text-primary">{features.heading_1}</h4>
                                                    <p className="text-primary">{features.text_1}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="pt-6 h-100">
                                    <div class="card h-100 rounded-4 stat-card shadow-sm">
                                        <div class="card-body">
                                            <div class="feature">
                                                <div class="feature-media mt-n8 mb-3">
                                                    <div class="media media-3xl media-middle media-border text-bg-primary-soft-outline rounded-4">
                                                        <img src={`${Helpers.imgUrl}${features.icon_2}`} className="w50" alt="" />
                                                    </div>
                                                </div>
                                                <div class="feature-text">
                                                    <h4 class="title text-primary fz-50">{features.heading_2}</h4>
                                                    <p className="text-primary">{features.text_2}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="pt-6 h-100">
                                    <div class="card h-100 rounded-4 stat-card shadow-sm">
                                        <div class="card-body">
                                            <div class="feature">
                                                <div class="feature-media mt-n8 mb-3">
                                                    <div class="media media-3xl media-middle media-border text-bg-primary-soft-outline rounded-4">
                                                        <img src={`${Helpers.imgUrl}${features.icon_3}`} className="w50" alt="" />
                                                    </div>
                                                </div>
                                                <div class="feature-text">
                                                    <h4 class="title text-primary fz-50">{features.heading_3}</h4>
                                                    <p className="text-primary">{features.text_3}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section-lg section-bottom-0 has-shape">
                <div class="nk-shape bg-shape-border-d mt-n8 mt-lg-n9 start-50 translate-middle-x"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-9 col-xl-7">
                                <h2 class="title">{optimizationContent.heading}</h2>
                                <p class="lead">{optimizationContent.paragraph}</p>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs">
                            <div class="col-lg-4">
                                <div class="card bg-gradient-light border-0 h-100">
                                    <div class="card-body p-3 d-flex">
                                        <div class="feature feature-inline align-items-center">
                                            <div class="feature-text me-auto">
                                                <h4 class="title">{optimizationContent.card_heading_1}</h4>
                                                <p>{optimizationContent.card_text_1}</p>
                                            </div>
                                            <div class="feature-decoration flex-shrink-0 ms-4 me-n3"><img src="images/number/1-light.png" alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card bg-gradient-light border-0 h-100">
                                    <div class="card-body p-3 d-flex">
                                        <div class="feature feature-inline align-items-center">
                                            <div class="feature-text me-auto">
                                                <h4 class="title">{optimizationContent.card_heading_2}</h4>
                                                <p>{optimizationContent.card_text_2}</p>
                                            </div>
                                            <div class="feature-decoration flex-shrink-0 ms-4 me-n3"><img src="images/number/2-light.png" alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card bg-gradient-light border-0 h-100">
                                    <div class="card-body p-3 d-flex">
                                        <div class="feature feature-inline align-items-center">
                                            <div class="feature-text me-auto">
                                                <h4 class="title">{optimizationContent.card_heading_3}</h4>
                                                <p>{optimizationContent.card_text_3}</p>
                                            </div>
                                            <div class="feature-decoration flex-shrink-0 ms-4 me-n3"><img src="images/number/3-light.png" alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features-section" class="section section-lg section-bottom-0">
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-10 col-xl-9 col-xxl-8">
                                <h2 class="title h2">{strategies.heading}</h2>
                                <p class="lead">{strategies.paragraph}</p>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row gy-gs gx-xxl-9">
                            {
                            strategiesList.map((e, index)=>{
                                return (
                                <div class="col-md-6" key={index}>
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class={e.iconClass}></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">{e.heading}</h4>
                                        <p>{e.paragraph}</p>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section-lg section-bottom-0 has-shape">
                <div class="nk-shape bg-shape-blur-g mt-n20p start-50"></div>
                <div class="nk-shape bg-shape-blur-h translate-middle-y top-50 end-50"></div>
                <div class="nk-shape bg-shape-blur-i mb-n30p bottom-0 start-50"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-xl-11 col-xxl-10"><h2 class="title h1">    {howItWorks && howItWorks.heading ? howItWorks.heading : ""}</h2></div>
                        </div>
                    </div>
                    {howItWorksList.map((e,index) =>
                    <div class="section-content" key={index}>
                        <div class={`row g-gs justify-content-between justify-content-xl-center ${index % 2 === 0 ? "flex-md-row-reverse" : ""} align-items-center`}>
                            <div class={`col-lg-5 col-md-6 ${index % 2 == 0 ? "offset-xl-1": ""}`}>
                                <div class={`block-gfx position-relative`}>
                                    {/* <svg class="position-absolute top-0 start-0 mt-n5 ms-n3 me-6 z-index-n1" viewBox="0 0 413 367" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M297.773 365.385C240.904 379.987 171.136 291.086 138.891 279.276C75.5722 256.299 -32.89 263.6 9.61542 210.775C29.2559 186.295 70.2956 153.011 85.539 133.9C116.612 95.0327 55.6386 25.4583 110.749 5.05841C165.566 -15.1267 212.176 30.612 266.114 45.6435C301.584 55.736 339.692 50.7971 376.042 55.9508C400.959 59.6013 422.944 80.0012 408.287 99.9716C318.293 222.8 394.216 340.476 297.773 365.385Z"
                                            fill="#B4EBFC"
                                        />
                                    </svg> */}
                                    {e.svg}
                                    <img class="w-100 rounded-3 shadow-sm" src={`${Helpers.imgUrl}/howItWorks/${e.img}`} alt="" />
                                </div>
                            </div>
                            <div class={`col-xl-4 col-md-6 ${index % 2 != 0 ? "offset-xl-1": ""}`}>
                                <div class="block-text">
                                    <h6 class="text-primary">{e.no}</h6>
                                    <h3 class="title">{e.heading}</h3>
                                    <p>{e.paragraph}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                </div>
            </section>
            <section class="section section-lg section-bottom-0">
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-9 col-xl-8 col-xxl-7"><h2 class="title h1">What amazing content will you create with AI?</h2></div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs">
                            {prompts.map(prompt => {
                                return (
                                    <div class="col-md-6 col-xl-4">
                                        <div class="card rounded-4 border-0 shadow-sm h-100">
                                            <div class="card-body">
                                                <div class="feature">
                                                    <div class="feature-media">
                                                        <div class="media media-middle media-lg text-white bg-primary rounded-3">{ prompt.name.charAt(0) }</div>
                                                    </div>
                                                    <div class="feature-text">
                                                        <h4 class="title">{ prompt.name }</h4>
                                                        <p>{ prompt.description }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div class="section-actions text-center">
                        <ul class="btn-list btn-list-inline g-gs">
                            <li>
                                <Link to="/register" class="btn btn-primary btn-lg rounded-pill"><span>Get Started For Free</span><em class="icon ni ni-arrow-long-right"></em></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="section section-lg has-shape">
                <div class="nk-shape bg-shape-blur-k end-50 top-0"></div>
                <div class="nk-shape bg-shape-blur-l start-50 top-75"></div>
                <div class="container">
                    <div class="section-content">
                        <div class="row justify-content-center text-center">
                            <div class="col-xl-6 col-lg-8 col-md-10">
                                <div class="block-text">
                                    <h2 class="title h1">Ready to get started?</h2>
                                    <p class="lead mt-3">It's like having access to a team of copywriting experts writing powerful copy for you in 1-click.</p>
                                    <ul class="btn-list btn-list-inline">
                                        <li>
                                            <Link to="/register" class="btn btn-lg btn-primary rounded-pill"><span>Get Started For Free</span><em class="icon ni ni-arrow-long-right"></em></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Home;