const RegisteredUser = () => {

    const activateFreeTrial = () => {

    }

    return (
        <main class="nk-pages">
            <div className="nk-hero pt-lg-6 pb-xl-4">
                <div className="container">
                    <div className="row g-gs align-items-center justify-content-center justify-content-xl-between flex-xl-row-reverse text-center text-xl-start">
                        <div className="col-xl-6 col-xxl-5 col-lg-7 col-md-10">
                            <div className="nk-hero-gfx me-xxl-n7">
                                <div className="p-1 rounded-3">
                                    <img className="w-100 rounded-3" src="/assets/app/ai-writing.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6 col-lg-11">
                            <div className="nk-hero-content">
                                <h5 className="text-uppercase fw-normal mb-3">Get Started with eComEmail.AI</h5>
                                <h1 className="title mb-3 mb-lg-4">Active Your <span className="text-gradient-primary">Free Trial Now.</span></h1>
                                <p className="lead">Unlock full access effortlessly - activate your free trial now and experience premium features immediately!</p>
                                <ul className="btn-list btn-list-inline py-3 gy-3">
                                    <li>
                                        <button onClick={activateFreeTrial} className="btn btn-primary btn-lg"><span>Active Free Trial</span> <em className="icon ni ni-arrow-long-right"></em></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default RegisteredUser;