import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Helpers from "../Config/Helpers";
import axios from "axios";
import PageLoader from "../Components/Loader/PageLoader";
import { Player, ControlBar } from 'video-react';
import { IoMdClose } from "react-icons/io";


const Layout = () => {
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userType, setUserType] = useState('');
    const [prompts1, setPrompts1] = useState([]);
    const [prompts2, setPrompts2] = useState([]);
    const [hero, setHero] = useState([]);
    const [loader, setLoader] = useState(false)
    const [toggleMobile, setToggleMobile] = useState(false);

    const getPrompts = () => {
        axios.get(`${Helpers.apiUrl}all-prompts`).then(response => {
            setPrompts1(response.data.prompts1);
            setPrompts2(response.data.prompts2);
        });
    }

    const getHome = () => {
        setLoader(true)
        axios.get(`${Helpers.apiUrl}hero/all`).then(response => {
            setHero(response.data.content);
            setLoader(false);  
        })
    }


    const checkUser = () => {
        let token = Helpers.getItem("token");
        let user = Helpers.getItem("user", true);
        if(user &&  token){
            setIsLoggedIn(true);
            if(user.user_type == 1){
                setUserType("admin");
            }else{
                setUserType("user");
            }
        }
    }


    useEffect(() => {
        getHome();
        Helpers.toggleCSS();
        checkUser();
        getPrompts();
    }, [location.pathname]);


    return (
        <div className="nk-app-root">
            <header className="nk-header has-mask">
                <div className="nk-mask bg-gradient-a"></div>
                <div className="nk-header-main nk-menu-main will-shrink is-transparent ignore-mask">
                    <div className="container">
                        <div className="nk-header-wrap">
                            <div className="nk-header-logo mt--10">
                                <a href="/" className="logo-link">
                                    <div className="logo-wrap">
                                        <img className="logo-img logo-125 logo-light" src="/logo.png" srcset="/logo.png 2x" alt="" />
                                        <img className="logo-img logo-125 logo-dark" src="/logo.png" srcset="/logo.png 2x" alt="" />
                                    </div>
                                </a>
                            </div>
                            <div className="nk-header-toggle">
                                {/* <button className="dark-mode-toggle"><em className="off icon ni ni-sun-fill"></em><em className="on icon ni ni-moon-fill"></em></button> */}
                                <button className="btn btn-icon header-menu-toggle" onClick={()=>setToggleMobile(!toggleMobile)}><em className="icon ni ni-menu"></em></button>
                            </div>
                            <nav className={`nk-header-menu nk-menu ${toggleMobile ? "transform-0" : ""}`}>
                                <IoMdClose 
                                className="mx-2 fs-3 mobile-view-only" 
                                style={{display: toggleMobile ? 'block': "none",alignSelf: "end"}}
                                onClick={()=>setToggleMobile(!toggleMobile)}></IoMdClose>
                                <ul className="nk-menu-list mx-auto">
                                    <li className="nk-menu-item">
                                        <Link to="/" className="nk-menu-link"><span className="nk-menu-text">Home</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to="/use-cases" className="nk-menu-link"><span className="nk-menu-text">Use Cases</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <a href="/terms-and-conditions" className="nk-menu-link"><span className="nk-menu-text">Terms</span></a>
                                    </li>
                                    <li className="nk-menu-item">
                                        <a href="/privacy-policy" className="nk-menu-link"><span className="nk-menu-text">Privacy</span></a>
                                    </li>
                                </ul>
                                <ul className="nk-menu-buttons flex-lg-row-reverse">
                                    {isLoggedIn && <li><Link to={`/${userType}/dashboard`} className="btn btn-primary">{userType === 'admin' ? 'Dashboard' : 'Start Writing'}</Link></li>}
                                    {!isLoggedIn && <li><Link to={`/register`} className="btn btn-primary">Get Started For Free</Link></li>}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {location.pathname === "/" && (
                    <div className="nk-hero pt-4 pt-lg-6 pt-xl-12 pb-xl-4">
                        {loader ? <PageLoader/> : 
                        <div className="container">
                            <div className="row g-gs align-items-center justify-content-center justify-content-xl-between flex-xl-row-reverse text-center text-xl-start">
                                <div className="col-xl-6 col-xxl-5 col-lg-7 col-md-10">
                                    <div className="nk-hero-gfx me-xxl-n7">
                                        <div 
                                        // pt-1 px-1 when video element
                                        className={`${(hero.media_type === "video") ? "pt-1 px-1" : "p-1"} rounded-3 bg-gradient-primary`}
                                        >
                                            {hero.media_type === "photo" &&<img className="w-100 rounded-3" src={`${Helpers.imgUrl}${hero.image}`} alt="" />}
                                            {hero.media_type === "video" && <Player className="w-100 rounded-3"
                                            autoPlay controls={false} fluid={false} height={"auto"} width={"auto"}
                                            // src={`${Helpers.imgUrl}${hero.video}`}
                                            src={`${hero.video}`}
                                            >
                                                <ControlBar autoHide={true} disableCompletely={true} disableDefaultControls={true} className="my-class" />
                                            </Player>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-7 col-xl-6 col-lg-11">
                                    <div className="nk-hero-content">
                                        <h5 className="text-uppercase fw-normal mb-3">{hero.welcome}</h5>
                                        <h1 className="title mb-3 mb-lg-4">{hero.heading} <span className="text-gradient-primary">{hero.highlighted_heading}</span>.</h1>
                                        <p className="lead">{hero.paragraph}</p>
                                        <ul className="btn-list btn-list-inline py-3 gy-3">
                                            {/* <li>
                                                <a href="/" className="btn btn-primary btn-lg"><em className="icon ni ni-google"></em><span>Sign up with Google</span></a>
                                            </li> */}
                                            <li>
                                                <Link to="/register" className="btn btn-primary btn-lg"><span>Get Started For Free</span> <em className="icon ni ni-arrow-long-right"></em></Link>
                                            </li>
                                        </ul>
                                        {/* <p className="sub-text mt-2"><strong>*100% free </strong> to get started. No credit card required.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                )}
            </header>
            <Outlet />
            <footer class="nk-footer">
                <div class="section section-sm section-top-0">
                    <div class="container">
                        <div class="row g-5">
                            <div class="col-xl-4 col-lg-7 col-md-9 me-auto">
                                <div class="block-text pe-xxl-5">
                                    <a href="/" class="logo-link mb-4">
                                        <div class="logo-wrap">
                                            <img class="logo-img logo-200 logo-light" src="/logo.png" srcset="/logo.png 2x" alt="" />
                                            <img class="logo-img logo-200 logo-dark" src="/logo.png" srcset="/logo.png 2x" alt="" />
                                        </div>
                                    </a>
                                    <p className="mt-3">EcomEmail.ai: Revolutionize your e-commerce marketing with intelligent, data-driven email solutions. Boost sales, engagement, and customer loyalty effortlessly.</p>
                                </div>
                            </div>
                            <div class="col-xl">
                                <div class="row g-gs">
                                    <div class="col-lg-3 col-sm-4 col-6">
                                        <div class="wgs">
                                            <h6 class="wgs-title overline-title text-heading">Company</h6>
                                            <ul class="list gy-2 list-link-base">
                                                <li><a class="link-base" href="/">About Us</a></li>
                                                <li><Link class="link-base" to="/privacy-policy">Privacy Policy</Link></li>
                                                <li><Link class="link-base" to="/cookie-policy">Cookie Policy</Link></li>
                                                <li><Link class="link-base" to="/disclaimer">Disclaimer</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-4 col-6">
                                        <div class="wgs">
                                            <h6 class="wgs-title overline-title text-heading">Use Case</h6>
                                            <ul class="list gy-2 list-link-base">
                                                {prompts1.map(prompt => <li><span class="link-base" >{ prompt.name }</span></li>)}
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-4 col-6">
                                        <div class="wgs">
                                            <h6 class="wgs-title overline-title text-heading">Use Case</h6>
                                            <ul class="list gy-2 list-link-base">
                                                {prompts2.map(prompt => <li><span class="link-base" >{ prompt.name }</span></li>)}
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-5 col-sm-6">
                                        <div class="wgs">
                                            <h6 class="wgs-title overline-title text-heading">Get In Touch</h6>
                                            <ul class="list gy-3">
                                                <li><em class="icon text-primary fs-5 ni ni-mail-fill"></em> <span>support@ecomemail.ai</span></li>
                                                <li><em class="icon text-primary fs-5 ni ni-call-alt-fill"></em> <span>+1 215 285 5702</span></li>
                                                <li><em class="icon text-primary fs-5 ni ni-map-pin-fill"></em> <span>442 Belle St Floor 7, San Francisco, AV 4206</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section section-0">
                    <hr class="border-opacity-25 border-primary m-0" />
                    <div class="container">
                        <div class="py-4">
                            <div class="row">
                                <div class="col-md">
                                    <p class="mb-2 mb-md-0">Copyright &copy; 2023. <a href="/" class="fw-bold text-base">EComEmail.AI</a>.</p>
                                </div>
                                <div class="col-md">
                                    <ul class="list list-row gx-4 justify-content-start justify-content-md-end">
                                        <li><Link to="/terms-and-conditions" class="link-primary">Terms & Conditions</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}


export default Layout;